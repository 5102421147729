import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//26-jun-2023 add css 
import "./InfoArea.css"
import styles from "assets/jss/material-kit-react/components/infoStyle.js";
//06-jul-2023 
import useWindowDimensions from "../../useWindowDimensions";


const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const { title, description, iconColor, vertical } = props;
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical
  });
  return (
    <div 

    //  className={classes.infoArea}
     className={classes.infoArea}

     style={{backgroundColor:"#e2e1ef",margin:width<500?"5%":"8% auto 0% auto"}}>
      <div className={iconWrapper}>
        {/* <props.icon className={iconClasses} /> */}
        <img className={iconClasses} src={props.icon}></img>
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p style={{color:"black",textAlign:"justify",marginLeft:10,marginRight:10}} className={classes.description}>{description}</p>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray"
};

InfoArea.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  vertical: PropTypes.bool
};
