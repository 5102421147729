import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';


// import Button from "components/CustomButtons/Button.js";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";
// import LocationCityIcon from '@mui/icons-material/';
import BusinessIcon from '@material-ui/icons/LocationCity';
import EmailIcon from '@material-ui/icons/Email';


import CallIcon from '@material-ui/icons/Call';





// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// import team1 from "assets/img_stock/bio_chem_1.JPG";
// import P1 from "assets/img_stock/FutureScientific/Products/New/chorus.jpg";
import P1 from "assets/img_stock/FutureScientific/Products/New2/Chorus.jpg";















const useStyles = makeStyles(styles);

export default function   ProductSectionDetails() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      {width<500?
      <h3 className={classes.title} style={{color:"black"}}>Contact Us</h3>
:
<h2 className={classes.title} style={{color:"black"}}>Contact Us</h2>

      }
      <div>
        <GridContainer style={{display:"flex",justifyContent:"center",alignItems:"center",}}>
          {/* minHeight:"400px", add before eid day 27-jun-2023 Dowload Brochure button opr niche hai es k lye same size ki image dalni hai */}
          <GridItem xs={12} sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignIttem:"center",}}>
            
               
                  <div
                style={{width:"100%",display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column",}}>


                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,
                    borderColor:"#3e3e8a",backgroundColor:"#e2e1f0",borderStyle:"double solid double solid",borderWidth:"2px",paddingLeft:width<500?"20%":"0%",paddingRight:width<500?"20%":"25%",
                    }}>
              {width<500?<></>:
              <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon> 
            }
              <div style={{display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column"}}>
              {/* <a style={{color:"#3e3e8a"}} href="tel:0514577810">Office Number </a> */}
              <a style={{color:"#3e3e8a"}} href="tel:0514577810">Phone Number </a>

              <a style={{color:"black"}} href="tel:0514577810">+92-51-4577810</a>
              {/* <a style={{color:"#3e3e8a"}} href="tel:0514478510">Phone Number</a> */}

<a style={{color:"black"}} href="tel:0514478510"> +92-51-4478510</a>

             

              </div>
          

             
              </div>
                {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%"
                   , borderColor:"#3e3e8a",backgroundColor:"#e2e1f0",borderStyle:"double solid double solid",borderWidth:"2px",paddingLeft:"19%",paddingRight:"19%",
              
              }}>
              {width<500?<></>:
               <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon> 
              }
                <div style={{display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column"}}>
                <a style={{color:"#3e3e8a"}} href="tel:03201231014">Phone Number</a>

                <a style={{color:"black"}} href="tel:03201231014">+92-3201231014</a>
                
                </div>
              

               
                </div> */}
            
              {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%",
                    borderColor:"#3e3e8a",backgroundColor:"#e2e1f0",borderStyle:"double solid double solid",borderWidth:"2px",paddingLeft:width<500?"20%":"0%",paddingRight:width<500?"20%":"25%",
                  }}>
              {width<500?<></>:
               <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon>
            }
              <div style={{display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="tel:0514478510">Phone Number</a>

              <a style={{color:"black"}} href="tel:0514478510"> +92-51-4478510</a>


              </div>
              
     

             
              </div> */}

              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%",
                    borderColor:"#3e3e8a",backgroundColor:"#e2e1f0",borderStyle:"double solid double solid",borderWidth:"2px",paddingRight:width<500?"2%":"6%",paddingLeft:width<500?"2%":"0%"
                  }}>
              {width<500?<></>:
               <EmailIcon style={{width:width<500?30:50,height:width<500?30:50,color:"#3e3e8a",}}></EmailIcon> 
    }
              <div style={{display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="mailto:info@futurescientific.com.pk">Email Address</a>
{width<500?
              <a style={{color:"black"}} href="mailto:info@futurescientific.com.pk">info@futurescientific.com.pk</a>
:
<a style={{color:"black"}} href="mailto:info@futurescientific.com.pk">info@futurescientific.com.pk</a>

}



              </div>
              
     

             
              </div>
              
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%",width:width<500?"95%":"55%",
                    borderColor:"#3e3e8a",backgroundColor:"#e2e1f0",borderStyle:"double solid double solid",borderWidth:"2px",
                  }}>
             {width<500?<></>: 
             <BusinessIcon style={{width:width<500?30:50,height:width<500?30:50,color:"#3e3e8a",}}></BusinessIcon> 
}

              <div style={{display:"flex",justifyContent:"center",alignItems:width<500?"center":"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="mailto:info@futurescientific.com.pk">Address</a>

              <a style={{color:"black",textAlign:width<500?"center":"start",marginLeft:"1%",marginRight:"1%"}} >FS House, Opposite Street No 04, Main Road, Shaheen Town, Gangaal West, Rawalpindi, Punjab, Pakistan</a>
              




              </div>
            
              </div>
              {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginLeft:"8%"}}>
            
              <a style={{color:"black"}} > Shaheen Town, Rawalpindi, Punjab,Pakistan</a>
             
              </div> */}
           {width<500?
      <h3 className={classes.title} style={{color:"black"}}>Social Media Links</h3>
:
<h3 className={classes.title} style={{color:"black"}}>Social Media Links</h3>

           }   

              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,}}>
              
              {/* <BusinessIcon style={{width:width<500?30:50,height:width<500?30:50,color:"#3e3e8a",}}></BusinessIcon>
               */}
{/* <CallIcon style={{width:25,height:25,color:"white",marginRight:"3%"}}></CallIcon> */}
<a style={{color:"#3e3e8a"}} href="https://www.linkedin.com/company/futurescientific/">
<LinkedInIcon  style={{width:70,height:70,color:"#3e3e8a"}}></LinkedInIcon>
         </a>
         <a style={{color:"#3e3e8a"}} href="https://www.facebook.com/www.futurescientific.com.pk?mibextid=9R9pXO">
<FacebookIcon style={{width:70,height:70,color:"#3e3e8a"}}></FacebookIcon>
</a>
   
              </div>
              
                
              
                </div>
                {/* 2nd half */}
                            
          
              
            
            {/* </div> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignIttem:"center",marginTop:width<500?"10%":""}}>
          <div
                style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column",}}>


         
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.798494683826!2d73.12059897515762!3d33.61053244092565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb340fba6ffb%3A0x3905f8dcd40f6fd9!2sFuture%20Scientific!5e0!3m2!1sen!2s!4v1703332786267!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              
                
              
                </div>
</GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
            <div plain style={{width:width<500?"100%":"100%",boxShadow:"10px 10px 8px 8px #9d9d9d",}}> */}
              {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{}}> */}
                {/* <img src={P1} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // marginRight:"-20%",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/> */}
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              CHORUS
               </h4>
              <CardBody >
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Chorus is an automated immunoassay analyzer based on ready-to- use and single determination diagnostic devices for the diagnosis of infectious diseases, autoimmunity, endocrinology, tumor markers, allergy, bone metabolism & inflammation while ensuring maximum flexibility in organizing the clinical laboratory’s workflow.
                </p>
              </CardBody>
             <a   href={P1Brocher} download="ChorusBrochure.pdf">

              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>
                Dowload Brochure</Button>
                </a> */}
            
            {/* </div>
          </GridItem> */}
         </GridContainer>
      </div>
    </div>
  );
}
