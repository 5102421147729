import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// import Button from "components/CustomButtons/Button.js";
//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";



// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// import team1 from "assets/img_stock/bio_chem_1.JPG";
import team1 from "assets/img_stock/New2023/Department/1new1.jpg";

import team2 from "assets/img_stock/New2023/Department/2.jpg";
import team3 from "assets/img_stock/New2023/Department/3.jpg";
import team4 from "assets/img_stock/New2023/Department/4.jpg";
import team5 from "assets/img_stock/New2023/Department/5.jpg";
import team6 from "assets/img_stock/New2023/Department/6new.jpg";


const useStyles = makeStyles(styles);

export default function TeamSection() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Our Laboratory Services</h2>
      <div>
        <GridContainer>
          {/* minHeight:"400px", add before eid day 27-jun-2023 see more button opr niche hai es k lye same size ki image dalni hai */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
            <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>
                <img src={team1} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // marginRight:"-20%",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              CLINICAL CHEMISTRY & IMMUNOLOGY
                <br />
               </h4>
              <CardBody >
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The Clinical Chemistry & Immunology Section of the Pathology Department is core of the organization, specializes in performing a variety of laboratory assays that provides crucial information to physicians for making informed decisions.
                </p>
              </CardBody>
              <NavLink to="/Clinic_Chemistry&Immunology" style={{marginBottom:"5%"}} >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>

           {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                  <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team2}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              HEMATOLOGY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The Hematology Section of the Pathology Department is staffed by a team of highly qualified hematologists and laboratory scientists who use state of the art analyzers to perform a wide range of hematological tests.
                </p>
              </CardBody>
              <NavLink to="/Hematology" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team3}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              MICROBIOLOGY & CLINICAL PATHOLOGY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                With state-of-the-art equipment and highly qualified personnel, MPL's Microbiology and Clinical Pathology section strives to provide accurate and timely detection and identification of infectious agents, including bacteria viruses, fungi, and parasites.
                </p>
              </CardBody>
              <NavLink to="/Microbiology&ClinicalPathology" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
        {/* </GridContainer>
        <br/>
        <GridContainer> */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
            <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>
                <img src={team4}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              MOLECULAR PATHOLOGY & GENETICS
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The Molecular Pathology & Genetics section specializes in molecular testing for different diseases. Highly trained molecular biologists, state-of-the-art equipment, and strict quality control measures ensure accurate and reliable results.
                </p>
              </CardBody>
              <NavLink to="/Molecular" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>

           {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                  <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team5} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              HISTHOPATHOLOGY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The Histopathology Section specializes in the study of tissues of the human body. This section is equipped with state-of-the-art technology and staffed by highly qualified and experienced Histopathologists to provide accurate and timely diagnoses.
                </p>
              </CardBody>
              <NavLink to="/Histopathology" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team6} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              SPECIAL PATHOLOGY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                MPL's special pathology department focuses on the efficient diagnosis of rare disorders. In our
special pathology department, we are performing different unique assays including cytogenetic studies, Platelet aggregation
studies, IGRA assays etc.
                </p>
              </CardBody>
              <NavLink to="/Special_Pathology" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>


              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require('assets/img_stock/Molecular_Genetics.JPG')} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Pathology & Microbiology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Clinical pathology & Microbiology department is equipped with Cobas U411 for urine analysis, fully automated Sperm health analyzer by Biola Russia , Automated  blood culture system  by BD USA. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/histopathology_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Histopathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/moleculer_dep_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Biology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  Our Gene expert system also provides Drug resistance to Tuberculosis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/pathology_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Special Pathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our special pathology department we are performing Cytogenetic studies, Platelet aggregation studies, Interferon gamma release assay ( T SPOT TB Test) for the accurate diagnosis of Tuberculosis , Immunoflourescence studies for Anti-nuclear antibodies, Urea Breath test for accurate diagnosis of H.Pylori infections, Hydrogen Breath Tests for the intolerances of different sugars.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/animations/virus (12).png")} alt="..." 
                  alt="..."
                  width={"70%"}
                  className={classes.imgFluid+" "+"funfact-one__virus-1"}
                />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Covid 19 PCR Lab
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                MPL has established a dedicated Covid-19 PCR testing Laboratory. This  BSL-2 Covid lab is equipped with state of the art PCR systems by Hibergene diagnostics Ireland. The high quality CE marked and IVD approved kits are being used for testing which are also made by Hibergene Diagnostics Ireland. Currently we have a PCR testing capacity of 2000 per day.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/molecular_gen_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Genetics
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our molecular genetics department is lead by Dr.Hani Akbar, a well-known clinical geneticist. We provide counseling and diagnostic facilities of genetic disorders to Patients and clinicians. We are collaborating with different international laboratories for outsource testing of genetic markers.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/x-ray.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Imaging & Radiology Department
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our Imaging & Radiology department we are providing digital X-Ray & Ultrasound facilities to our customers with the help of our highly qualified panel of Radiologists and sonologists . We are equipped with latest X-Ray and ultrasound machines to meet the clinician’s needs. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
         
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer >
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>Our Prime Location</h2>
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53115.91986215992!2d73.026057615748!3d33.68966667301035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf43bd76f797%3A0xada34418cc053a99!2sMetropole%20Laboratories%20Private%20Limited!5e0!3m2!1sen!2s!4v1598854374278!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
             
          </GridItem>
          </GridContainer> */}
      </div>
    </div>
  );
}
