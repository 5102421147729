import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text,Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../mpl-logo.png'
import footer from '../mpl-footer.png'
import Signature from '../Signature.png'


Font.register({ family: 'Calibri Regular', src: require('../../../fonts/Calibri Regular.ttf'),  });

Font.register({ family: 'Calibri Bold', src: require('../../../fonts/calibri-bold.ttf') });
const styles = StyleSheet.create({
    page: { paddingTop:150,paddingBottom:180,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    qrcode:{
        width:60,
        height:60
    },
    logo: {
        width: 200,
        height: 100
    },
    barcode: {
        width: 150,
        height: 40
    }
  });

  
  const CertificateTemplate=({invoice})=> {
     
   return (<Document  
   
   >
      <Page style={styles.page} size="A4" wrap 
            >
      <View fixed style={{
                width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                paddingLeft: 20,
                alignItems:"center",
                fontFamily:"Calibri Regular",
                paddingRight: 20,
                paddingTop: 10
            }} >
              
        </View>
            <View
            style={{
                flex:1,
                flexDirection:"column",
                padding:50,
                marginTop:-30
            }}
            >
        <InvoiceTitle title={"Medical Certificate for International Passengers"} />
        <Text style={{fontSize:12, marginTop:10}}>I <Text style={{fontFamily:"Helvetica-Bold"}}>Dr. Amna Afzal</Text> is a certified Medical Doctor holding a medical license no <Text style={{fontFamily:"Helvetica-Bold"}}>50420-P</Text> have examined <Text style={{fontFamily:"Helvetica-Bold"}}>{invoice.toAddress+" "+invoice.name}</Text> on dated <Text style={{fontFamily:"Helvetica-Bold"}}>{invoice.specimen_date}</Text> and have found the patient free from Corona Virus 2019 (COVID-19) disease and Fit to Fly. 
</Text>
<Text style={{
    fontSize:12,
    marginTop:15
}}>
Note: The above certificate is based on the clinical symptoms and COVID-19 PCR Testing done around the time of clinical examination.
</Text>
<Text  style={{
    fontSize:12,
    marginTop:15
}} >
COVID-19 PCR Sample Time & Date: <Text style={{fontFamily:"Helvetica-Bold"}}>{invoice.specimen_time}</Text> on <Text style={{fontFamily:"Helvetica-Bold"}}>{invoice.specimen_date}</Text>
</Text>
<Text  style={{
    fontSize:12,
    marginTop:15
}} >
The report of COVID-19 PCR is attached herewith. 
</Text>
        </View>
        <View style={{
            flex:1,
            flexDirection:"row",
            marginLeft:350,
            marginTop:-175
        }}>
             <Text style={{
                marginLeft:-50,
                fontSize:10,
                display:"absolute"
            }}>Signature :____________________________________</Text>
         
           
           
        </View>
       
        <View style={{
            flex:1,
            flexDirection:"column",
            marginLeft:350,
            marginTop:-170
        }}>
          
          <Text style={{
              fontSize:10,
              marginTop:20,
              marginLeft:63
          }}>Doctor Name : <Text style={{fontFamily:"Helvetica-Bold"}}>Dr. Amna Afzal</Text></Text>
           
           <Text style={{
              fontSize:10,
              marginTop:5,
              marginLeft:57
          }}>Registration/PMDC No: <Text style={{fontFamily:"Helvetica-Bold"}}>50420-P</Text></Text>
            <Text style={{
              fontSize:10,
              marginTop:5,
              marginLeft:112
          }}>Date: <Text style={{fontFamily:"Helvetica-Bold"}}>{invoice.result_date}</Text></Text>
        </View>
       
   
        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>
            <View>
                
                </View>
            </View>
      </Page>
    </Document>
  )
        }
  
export default CertificateTemplate