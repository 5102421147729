import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CovidTests from "./CovidTests"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';

import useWindowDimensions from "../../useWindowDimensions"

import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
const { width } = useWindowDimensions();

  const [reports,setReports]=React.useState('')
  useEffect(()=>{
    if(   (localStorage.getItem('patient_name').toLowerCase().includes("mpl-")  && localStorage.getItem('role')=='Admin') || (localStorage.getItem('role')=='Patient' || localStorage.getItem('role')=='Panel')){
     window.location.href="https://backup.mpl-labs.pk/"
    }else{
      setReports('true')
    }
     },[])
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'25em'}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
      
       
           
              <h1 className={classes.title}>Overall COVID-19 Summary</h1>
              <br />
            
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <div  >
         <img
                src={require("assets/img_stock/New2023/backgroundFinal7.jpg")}
                style={{ 
                maxWidth: "100%",
                height:width<500?200 : "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                marginTop:width<500?"22%":"8%",
                opacity:width<500?0.5:0.5
            
            }}
              />
              {/* <h1 className={classes.title} style={{position:"absolute"}}>Message From CEO </h1> */}

        {/* <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title} style={{marginTop:-40}}>Message From CEO </h1>
              
            
            
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
      {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-50:-200}}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >
          <img src={report} style={{
  width: '7em',}} class="center"/>
     
     <h3 style={{color:"black",fontWeight:"bold"}} >
     Overall COVID-19 Summary
              </h3>
           {reports=='true' ?  <CovidTests />: <></>}
            {/* <FreeHS_Msg /> */}
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
