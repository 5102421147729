import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import LoginInfo from './LoginInfo'
import LoginCredientials from './LoginCredientials'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 0.5,
    fontFamily: "Calibri Regular",
    borderColor: 'black'
  },
  LoginTitle:{
    color: 'black',
    fontSize: 9,
    fontFamily:'Times-Bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration:'underline'
},
});

const InvoiceItemsTable = ({ invoice }) => (
  invoice.list.map((item) => {
    return <View>
      <InvoiceTitle title={item.title} />
      <View style={styles.tableContainer}>
        <InvoiceTableHeader date={invoice.result_date} />
        <InvoiceTableRow items={item.reports} />
      </View>
      <View style={{
        paddingLeft: 10,
        paddingTop: 10,
        marginTop: 10,
        fontSize: 8,
        borderWidth: 0.5,
        borderColor: 'black'
      }}>
        <Text style={{
          fontSize: 8,
          fontFamily: 'Calibri Bold'

        }}>Specimen:</Text>
        <Text
          style={{
            padding: 2,
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Calibri Regular"
          }}
        >{`Nasopharyngeal Swab`}</Text>

        <Text style={{
          fontSize: 8,
          fontFamily: 'Calibri Bold'

        }}>Comments/Recommendations:</Text>
        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Calibri Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Calibri Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }}>{`This test is a rapid screening test for the qualitative detection of COVID-19 Antigen in Nasopharyngeal Swab.`}</Text></View>
        <View
          style={{
            padding: 2,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Calibri Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Calibri Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }}>{`A Negative result must be interpreted along with clinical observation, patient history and epidemiological information.`}</Text></View>
        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Calibri Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Calibri Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }} >{`A single negative or positive result might not exclude or include the possibility of corona virus infection. A repeat test is required with
        its confirmatory method i.e. RT PCR for COVID-19.`}</Text></View>

      </View>
     

      <View style={{
        paddingLeft: 10,
        paddingRight:10,
        paddingBottom:10
      }}>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: "100%",
          marginTop: 5,
          fontSize: 9,

        }}>
          <View style={{
            width: "60%"
          }}>
               <Text style={styles.LoginTitle}>{"Online Verification Platforms"}</Text>
            <LoginInfo />
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: "100%",
              fontSize: 7,
              borderWidth: 0.5

            }}
            >
              <Text style={{

                width: '33%',
                textAlign: 'left',
                paddingLeft: 8,
                padding: 5,
                fontFamily: "Calibri Regular",
                height: '100%',
                textAlign: 'center',
                fontSize: 10
              }}>{"mpl-labs.pk/Login"}</Text>
              <Text style={{

                width: '33%',
                textAlign: 'left',
                paddingLeft: 8,
                padding: 5,
                borderLeftColor: 'black',
                borderLeftWidth: 0.5,
                fontFamily: "Calibri Regular",
                height: '100%',
                textAlign: 'center',
                fontSize: 10
              }}>{"mpl-labs.pk/android"}</Text>
              <Text style={{

                width: '33%',
                textAlign: 'left',
                paddingLeft: 8,
                padding: 5,
                borderLeftColor: 'black',
                borderLeftWidth: 0.5,
                fontFamily: "Calibri Regular",
                height: '100%',
                textAlign: 'center',
                fontSize: 10
              }}>{"mpl-labs.pk/ios"}</Text>

            </View>
          </View>

          <View style={{
            width: "5%"
          }}></View>

          <View style={{
            width: "35%"
          }}>
            <Text style={styles.LoginTitle}>{"Online Credentials"}</Text>
            <LoginCredientials />
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: "100%",
              fontSize: 7,
              borderWidth: 0.5

            }}
            >
              <Text style={{

                width: '50%',
                textAlign: 'left',
                paddingLeft: 8,
                padding: 5,
                fontFamily: "Calibri Regular",
                height: '100%',
                textAlign: 'center',
                fontSize: 10
              }}>{item.id}</Text>

              <Text style={{

                width: '50%',
                textAlign: 'left',
                paddingLeft: 8,
                padding: 5,
                borderLeftColor: 'black',
                borderLeftWidth: 0.5,
                fontFamily: "Calibri Regular",
                height: '100%',
                textAlign: 'center',
                fontSize: 10
              }}>{item.password}</Text>

            </View>
          </View>
        
          


        </View>


       
      </View>
      <View>
      </View>
      <View>

      </View>
    </View>
  })

);

export default InvoiceItemsTable