import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#696969',
        backgroundColor: '#696969',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Helvetica-Bold',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    description: {
        width: '10%',
    },
    qty: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    rate: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    amount: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    prev1: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1
    },
    prev2: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        textAlign:'center'
    },
    car: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        textAlign:'center'
    },
    driver: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        textAlign:'center'
    }
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Tracking ID</Text>
        <Text style={styles.qty}>Sender</Text>
        <Text style={styles.car}>Car/Courier</Text>
        <Text style={styles.driver}>D.No / T.No of  Courier</Text>
        <Text style={styles.rate}>Branch</Text>
        <Text style={styles.amount}>Arrival Date</Text>
        <Text style={styles.prev1}>Arrival Time</Text>
        <Text style={styles.prev2}>Total Sample</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader