/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import url from '../../link'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../useWindowDimensions"
// import androidLogo from "../../assets/img_stock/New2023/android-logo.png"
// import appleLogo from "../../assets/img_stock/New2023/apple-logo.png"
import Logo from "../../assets/img_stock/FutureScientific/LogoForFooter.png"





import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <>
   {window.location.href!="https://backup.mpl-labs.pk/ResetPassword" && window.location.href!="https://backup.mpl-labs.pk/FlyDubai" && window.location.href!="https://backup.mpl-labs.pk/AirBlue" && window.location.href!="https://backup.mpl-labs.pk/Login" && window.location.href!="https://backup.mpl-labs.pk/Emirates" && window.location.href!="https://backup.mpl-labs.pk/Register" && window.location.href!="https://backup.mpl-labs.pk/RecallPassword" ?
  <>
    <GridContainer  style={{width:"80%",marginLeft:"10%",marginRight:"10%",marginTop:"4%"}}>
    {/* <GridItem xs={1} sm={6} md={3} lg={3} style={{display:"flex",justifyContent:"center",alignItem:"center",flexDirection:"column",}} >
 <div>
    <h3 style={{fontWeight:'bold',color:"white"}}>Follow Us</h3>
    </div>

      <div>
<LinkedInIcon style={{width:50,height:50,color:"white"}}></LinkedInIcon>
</div>
<div>
<FacebookIcon style={{width:50,height:50,color:"white"}}></FacebookIcon>
</div>
<div>
<TwitterIcon style={{width:50,height:50,color:"white"}}></TwitterIcon>
</div>
     
      </GridItem> */}
    
  
        <GridItem xs={12} sm={6} md={4} lg={4} style={{color:"white",display:"flex",justifyContent:"center",alignItem:"center",flexDirection:"column",marginLeft:width<1250?"0%":"2%"}}>
         <div style={{color:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start"}}>
         <img className='img-fluid m-0 me-2 p-0' src={`${Logo}`} style={{width:'200px',height:'200px'}}></img>
<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
<p style={{fontWeight:"bold",fontSize:20, marginTop:"5px",fontFamily:"revert-layer"}}>
Future Scientific
</p>
<p style={{marginTop:-10 }}>
 Delivering Health Care Innovations.
</p>
</div>
          {/* <div>
      <h3 style={{fontWeight:'bold',}}>Services</h3>
      </div> */}
      {/* <div style={{color:"#faa931"}} >

      <NavLink style={{color:"white"}}  to="/Clinic_Chemistry&Immunology" className={classes.dropdownLink}>
   Clinical Chemistry & Immunology
            </NavLink>
            </div> */}
            {/* <div style={{color:"#faa931"}} >
            <NavLink style={{color:"white"}}   to="/Hematology" className={classes.dropdownLink}>
            Hematology
                         </NavLink>
                         </div>
                         <div>
              
              <NavLink style={{color:"white"}}   to="/Microbiology&ClinicalPathology" className={classes.dropdownLink}>
                 Microbiology & Clinical Pathology

                </NavLink>
                </div>
                <div>

<NavLink style={{color:"white"}}  to="/Molecular" className={classes.dropdownLink}>
Molecular Pathology & Genetics
  </NavLink>
  </div>
  <div>

<NavLink  style={{color:"white"}} to="/Histopathology" className={classes.dropdownLink}>
       
Histopathology

          </NavLink>
          </div>
          <div>
            
            <NavLink style={{color:"white"}}  to="/Special_Pathology" className={classes.dropdownLink}>
            Special Pathology
              </NavLink>
              </div>
              <div>
                          <NavLink style={{color:"white"}}  to="/Ultrasound" className={classes.dropdownLink}>
                           Ultrasound
                                        </NavLink>
                                        </div>
                                        <div>
                          
                          <NavLink style={{color:"white"}}   to="/Echocardiography" className={classes.dropdownLink}>
                         Echocardiography
                                       </NavLink>
                                       </div>
      <div>
                          
                          <NavLink  style={{color:"white"}}  to="/Fibroscan" className={classes.dropdownLink}>
                          Fibroscan
                          </NavLink>
                          </div>
                          <div>
                          
                          <NavLink  style={{color:"white"}}  to="/DigitalXray" className={classes.dropdownLink}>
                          Digital X-Ray
                          </NavLink>
                          </div>
              <div>
                          
                          <NavLink style={{color:"white"}} to="/OPG" className={classes.dropdownLink}>
                          OPG (Orthopantomogram)
                          </NavLink>
                          </div>
                          <div>
                          
                          <NavLink style={{color:"white"}}   to="/Spirometry" className={classes.dropdownLink}>
                          Spirometry 
                          </NavLink>
                          </div>
              */}
  

           
            
         
           
           
                       
                                       
                                              
                                      
                                       
                                       
                                        </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4} style={{marginLeft:width<1250?"0%":"5%"}}>
      <div >

        <h3 style={{fontWeight:'bold',color:"white"}}>Important Links</h3>
<div style={{color:"#faa931"}} >
      <NavLink style={{color:"white"}}  to="/" className={classes.dropdownLink}>
      Home
            </NavLink>
            </div>
            <div >
          <NavLink style={{color:"white"}} to="/AboutUs" className={classes.dropdownLink}>
About Us
</NavLink></div>
<div>

<NavLink  style={{color:"white"}} to="/Products" className={classes.dropdownLink}>
       
Products

          </NavLink>
        

{/* <NavLink  style={{color:"white"}} to="/Login" className={classes.dropdownLink}>
       
Online Reports

          </NavLink> */}
          </div>
          <div>

<NavLink  style={{color:"white"}} to="/Principles" className={classes.dropdownLink}>
       
Principles

          </NavLink>
          </div>
          <div>

<NavLink  style={{color:"white"}} to="/ContactUs" className={classes.dropdownLink}>
       
Contact Us

          </NavLink>
          </div>
          {/* <div>

<NavLink  style={{color:"white"}} to="/OurTeam" className={classes.dropdownLink}>
       
Our Team

          </NavLink>
          </div> */}
            {/* <div style={{color:"#faa931"}} >
            <NavLink style={{color:"white"}}   to="/Lab_Reports" className={classes.dropdownLink}>
            Lab Reports
                         </NavLink>
                         </div> */}
                           {/* <div>
              
              <NavLink style={{color:"white"}}   to="/NewsAndFeed" className={classes.dropdownLink}>
              News & Feed

                </NavLink>
                </div> */}
          
            
          
          
           
               
    
     
      
          {/* <div>

<NavLink style={{color:"white"}}  to="/OurGallery" className={classes.dropdownLink}>
Our Gallery
  </NavLink>
  </div> */}
          {/* <div>

<NavLink  style={{color:"white"}} to="/CompanyProfile" className={classes.dropdownLink}>
       
Company Profile

          </NavLink>
          </div> */}
          {/* <div>
              
              <NavLink style={{color:"white"}}   to="/Microbiology&ClinicalPathology" className={classes.dropdownLink}>
                 Microbiology & Clinical Pathology

                </NavLink>
                </div>
<div style={{color:"#faa931"}} >

      <NavLink style={{color:"white"}}  to="/Clinic_Chemistry&Immunology" className={classes.dropdownLink}>
   Clinical Chemistry & Immunology
            </NavLink>
            </div> */}
        
                        {/* <div>
            
                        <NavLink style={{color:"white"}}  to="/Special_Pathology" className={classes.dropdownLink}>
                        Special Pathology
                          </NavLink>
                          </div> */}
                          {/* <div>
                          <NavLink style={{color:"white"}}  to="/Ultrasound" className={classes.dropdownLink}>
                           Ultrasound
                                        </NavLink>
                                        </div> */}
                                        {/* <div>
                          
                                         <NavLink style={{color:"white"}}   to="/Echocardiography" className={classes.dropdownLink}>
                                        Echocardiography
                                                      </NavLink>
                                                      </div>
                                                      <div>
                          
                                        <NavLink  style={{color:"white"}}  to="/Fibroscan" className={classes.dropdownLink}>
                                        Fibroscan
                                        </NavLink>
                                        </div>
                                        <div>
                          
                                        <NavLink  style={{color:"white"}}  to="/DigitalXray" className={classes.dropdownLink}>
                                        Digital X-Ray
                                        </NavLink>
                                        </div>
                                        <div>
                          
                                        <NavLink style={{color:"white"}} to="/OPG" className={classes.dropdownLink}>
                                        OPG (Orthopantomogram)
                                        </NavLink>
                                        </div>
                                        <div>
                          
                                        <NavLink style={{color:"white"}}   to="/Spirometry" className={classes.dropdownLink}>
                                        Spirometry 
                                        </NavLink>
                                        </div> */}
                                        </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={3} style={{color:"white"}} >
      {/* <h5>Where to Find Us</h5> */}
      
      <div >
      <h3 style={{fontWeight:'bold'}}>Contact Us</h3>

      {/* <p>Metropole Laboratories Private Ltd,
20-C, Cresent Arcade, G-8 Markaz,
Islamabad</p> */}
      {/* <h5>Email Us At</h5> */}
      <div>
<BusinessIcon style={{width:25,height:25,color:"white",marginRight:"3%"}}></BusinessIcon>

      <a> FS House, Opposite Street No 04, Main Road, Shaheen Town, Gangaal West, Rawalpindi, Punjab, Pakistan</a>
      </div>
<div style={{marginTop:"2%"}}>

<EmailIcon style={{width:25,height:25,color:"white",marginRight:"3%"}}></EmailIcon>

      <a style={{color:"white"}} href="mailto:info@futurescientific.com.pk">info@futurescientific.com.pk</a>
      </div>
      {/* <h5>Call Us At</h5> */}
      <div style={{marginTop:"2%"}}>
<CallIcon style={{width:25,height:25,color:"white",marginRight:"3%"}}></CallIcon>

      <a style={{color:"white"}} href="tel:0514577810">051-4577810</a>
      <br></br>
      <a style={{color:"white",marginLeft:width<500?"11.5%":"13%"}} href="tel:0514577510">051-4478510</a>
     
   
      </div>
      {/* <div>
      <p>Follow Us</p>
      </div> */}
{/* <h4 style={{fontWeight:'bold'}}>Follow Us</h4> */}
{/*       
      <div style={{display:"flex", flexDirection:"row",marginTop:"2%"}}>
      <div  onClick={()=>{
          window.open('https://www.linkedin.com/company/mpl-labs/')
        }}>

      <LinkedInIcon style={{width:50,height:50,color:"white"}}></LinkedInIcon>
</div>
<div  onClick={()=>{
          window.open('https://www.facebook.com/MPL.Islamabad?mibextid=b06tZ0')
        }}>


<FacebookIcon style={{width:50,height:50,color:"white"}}></FacebookIcon>
</div>
<div  onClick={()=>{
          window.open(' https://twitter.com/MPL_Labs?s=08')
        }}>
<TwitterIcon style={{width:50,height:50,color:"white"}}></TwitterIcon>
</div>

</div> */}
{/* <h4 style={{fontWeight:'bold'}}>Download Our App</h4> */}
{/* <div style={{display:"flex", flexDirection:"row",marginTop:"2%"}}>
      <div >
      
      <img   src={androidLogo} 
        onClick={()=>{
          window.open('http://mpl-labs.pk/android')
        }}
            width={width<500?"100%":"100%"}
            height={width<500?"100%":"100%"}
               />
</div>
<div style={{marginLeft:"2%"}}>
      
      <img src={appleLogo}
       onClick={()=>{
        window.open('http://mpl-labs.pk/ios')
      }} 
            width={width<500?"100%":"100%"}
            height={width<500?"100%":"100%"}
               />
</div>

</div> */}
{/* <div style={{display:"flex", flexDirection:"row",marginTop:"2%"}}> */}
      {/* <div>
      <img src={appleLogo} 
            width={width<500?"50%":"80%"}
            height={width<500?"50%":"80%"}
               />
</div> */}

{/* </div> */}
  {/* <p style={{marginTop:"2%"}}>Metropole Laboratories Private Ltd, 20-C, Cresent Arcade, G-8 Markaz, Islamabad</p> */}
      </div>
        </GridItem>
        
      </GridContainer>
     
      </>
     
   :<></>}

 <footer className={footerClasses}>
      <div className={classes.container} style={{color:"white",borderTopColor:"white",borderTopWidth:0.5,borderTopStyle:"solid"}}>
       
         
            Future Sceintific Pakistan
            &copy; {1900 + new Date().getYear()}
      
      </div>
    </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
