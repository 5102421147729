/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import url from '../../link'
import useWindowDimensions from "../../useWindowDimensions";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import Carousel from "react-slick";

import one from "../../assets/img_stock/FutureScientific/OurHappyCustomer/01.jpg"
import two from "../../assets/img_stock/FutureScientific/OurHappyCustomer/02.jpg"
import three from "../../assets/img_stock/FutureScientific/OurHappyCustomer/03.jpg"
import four from "../../assets/img_stock/FutureScientific/OurHappyCustomer/04.jpg"
import five from "../../assets/img_stock/FutureScientific/OurHappyCustomer/05.jpg"
import six from "../../assets/img_stock/FutureScientific/OurHappyCustomer/06.jpg"
import seven from "../../assets/img_stock/FutureScientific/OurHappyCustomer/07.jpg"
import eight from "../../assets/img_stock/FutureScientific/OurHappyCustomer/08.jpg"
import nine from "../../assets/img_stock/FutureScientific/OurHappyCustomer/09.jpg"
import ten from "../../assets/img_stock/FutureScientific/OurHappyCustomer/10.jpg"
import eleven from "../../assets/img_stock/FutureScientific/OurHappyCustomer/11.jpg"
import twl from "../../assets/img_stock/FutureScientific/OurHappyCustomer/12.jpg"
import therteen from "../../assets/img_stock/FutureScientific/OurHappyCustomer/13.jpg"








import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SlickCarousel() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  var settings = {
    // dots: true,
    infinite: true,
    speed: 6000,
    slidesToShow: 4,
    // fade: true,
    slidesToScroll: 4,
    autoplay: true,
    initialSlide: 0,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  
  return (
    <>
 
    
  

 
 <div style={{width:"98%",marginLeft:width<500?"2%":"2%",paddingBottom:"2%",marginTop:"5%"}} >
    {
        width<500?
        <h3 style={{fontWeight:"bold",marginBottom:"3%",textAlign:"center",alignSelf:"center"}}> Our Satisfied Customers </h3>
:
<h2 style={{fontWeight:"bold",marginBottom:"3%",textAlign:"center",alignSelf:"center"}}> Our Satisfied Customers </h2>

    }
        <Carousel {...settings}   
        autoplaySpeed={4}
        >
        
       
         
        
         
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30,}}>
          <img height="100%" width="90%" src={one} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={two} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={three} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={four} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={five} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={six} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={seven} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={eight} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={nine} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={ten} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={eleven} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={twl} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div><div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={therteen} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
         
         
         
         
         
         
        </Carousel>
      </div>
   
    </>
  );
}


