import React ,{useEffect} from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const columns = [
  { id: 'srno', label: 'Sr No' },
  { id: 'reportno', label: 'Report No' },
  { id: 'name', label: 'Passenger' },
  { id: 'cnic', label: 'CNIC' },
  { id: 'passport', label: 'Passort No' },
  { id: 'airline', label: 'AirLine' },
  { id: 'status', label: 'Report Status' },
  { id: 'branch', label: 'Collection Point' },
  { id: 'remarks', label: 'Remarks (if Any)' }
  
];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return hours + ':' + minutes ;
}


export default function Lab_Tests() {
  const classes = useStyles();
  const [date,setDate]=React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);
  const [org_rows,setOrg_Rows]= React.useState([]);
  const [summaryDate,setSummaryDate]=React.useState([]);
  const [loading,setLoading]=React.useState(true)
  const [branchDetail,setBranchDetail]=React.useState([]);
  const [data_export,setData_Export]=React.useState([])
  const [selectedDate,setSelectedDate]=React.useState(new Date())
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch,setBranch]=React.useState('All')
  const [state, setState] = React.useState({
    status:'All',
  });

const multiDataSet = [
  {
    
    columns: [
        {title: "Metropole Laboratories Private Limited",width: {wch: 50}, style:{alignment:{horizontal:'center'},font: {sz: "18", bold: true}}},//pixels width 
      
    ],
    data: summaryDate
},
  {
    columns: [
        {title: "Sr No",width: {wch: 50}, style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},//pixels width 
        {title: "Report No", width: {wch: 30},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},//char width 
        {title: "Passenger Name", width: {wch: 30},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "CNIC", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Passport No", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "AirLine", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Report Status", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Collection Point", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Remarks (If Any)", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}}
    ],
    data:data_export
}
];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
  };
  const getData= async(data)=>{

    var total_samples=data.length
    var test_performed=0
    var positive=0
    var negative=0
    var cancel=0
    var pending=0
    var counter=0
    var rows_export=[]
    var d=date.toISOString()
   var date_=new Date(d).ddmmyyy()
  var summary_data= [
   [ {value: "Summary of the Day "+date_, width: {wch: 50}, style: {alignment:{horizontal:'center'},font: {sz: "18", bold: true}}},
  ]
]
setSummaryDate(summary_data)
   var res= await data.map((item)=>{
        item.status=="Covid19 NOT Detected" ? negative=negative+1 : item.status=="Result Not Ready" ? pending=pending+1 : item.status=="Cancel/Refund" ? cancel=cancel+1 : item.status=="Covid19 Detected" ? positive=positive+1 : counter=counter+1
   var obj=[
  {value: item.srno, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:  item.reportno, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value: item.name, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:   item.cnic, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:  item.passport, style: {alignment:{horizontal:'center'},font: {sz: "10",}} },
  {value:  item.airline, style: {alignment:{horizontal:'center'},font: {sz: "10",}} },
  {value:  item.status, style: {alignment:{horizontal:'center'},font: {sz: "10",}} },
  {value:  item.branch, style: {alignment:{horizontal:'center'},font: {sz: "10",}} },
  {value:   item.remarks, style: {alignment:{horizontal:'center'},font: {sz: "10",}} },

   ]
    rows_export.push(obj)
   
        return counter

    })

    Promise.all(res).then(()=>{
      test_performed=positive+negative
            rows_export.push([])
            rows_export.push(
                [
                
             {   value: "Total Sample Collected", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}
                ,
                total_samples]
                
            )
            rows_export.push(
                [
                {   value: "Total Test Performed", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}   
                ,
                test_performed]
               
            )
            rows_export.push([
            
             {   value:  "COVID Negative Cases", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}   
           
             ,
           negative
            ])
            rows_export.push([
               
               {   value:  "COVID Positive Cases", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}   
        
               ,
         positive,
                
            ])
            rows_export.push([
           
             {   value:   "COVID Pending Cases", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}   
        
             ,
        pending
            ])
            rows_export.push([
             
              {   value:   "COVID Cancel/Refund Cases", style: {alignment:{horizontal:'center'},font: {sz: "14", bold: true} }}   
              ,
              cancel
                
            ])
           setData_Export(rows_export)
  })
}
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    
  
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver"
    ).then((res)=>res.json())
     .then((result)=>{
       setBranchDetail(result)
       var vSearchStr={
        "PIN":"",
        "PhoneNo":"",
        "PatientName":"",
        "RegDateFrom":date.toISOString(),
        "RegDateTo":date.toISOString(),
        "vBranchID":"0",
        "AirlineID":0,
        "Airline_Type":"KSA"
    }
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
        method:"POST",
        'Content-Type':"application/json",
        body:JSON.stringify(vSearchStr)
      }).then((res)=>res.json())
        .then((result)=>{
         var the_rows=[]
        var srno=1
    
         result.map((data)=>{
          console.log(data.Booking_Date)
          var br_title=""
       
          if(data.Collection_Point=="METROPOLE"){
            br_title="Islamabad"
          }else{
  
         br_title=data.Collection_Point.split('MPL,')[1]
          }
          if(data.Collection_Point.toLowerCase().includes('genomic rwp') || data.Collection_Point.toLowerCase().includes('ddlc') || data.Collection_Point.toLowerCase().includes('biogene') || data.Collection_Point.toLowerCase().includes('device solutions') || data.Collection_Point.toLowerCase().includes('medikay') || data.Collection_Point.toLowerCase().includes('blue area') || data.Collection_Point.toLowerCase().includes('mpl-mhc')){
            br_title="Islamabad"
                      }
                      if(data.Collection_Point.toLowerCase().includes('6th road rwp') || data.Collection_Point.toLowerCase().includes('falak air') || data.Collection_Point.toLowerCase().includes('abrar')   ){
                        br_title="Rawalpindi"
                      }
           var obj={
             srno:srno,
           reportno:data.Report_No,
           date:data.Booking_Date,
       name:data.Passenger_Name,
       passport:data.Passport_No,
       cnic:data.CNIC, 
       airline:data.Airline,
       status:data.Report_Status ,
       branch:br_title,
       remarks:data.Remarks_IfAny
           }
           srno=srno+1
          the_rows.push(obj)
          
         })
         
         setRows(the_rows)
         setOrg_Rows(the_rows)
         setLoading(false)
         getData(the_rows)
        })
     })
  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <Paper className={classes.root}>
    
      <GridContainer style={{padding:'2em'}}>
                <GridItem xs={6} sm={6} md={3} lg={3}>
                  <InputLabel className={classes.label}>
                  <b>  Date</b>
                  </InputLabel>
                  
                  <FormControl fullWidth>
                    <Datetime
                      inputProps={{ placeholder: "Summary of Date" }}
                      value={date}
                      timeFormat=""
                      onChange={(date)=>{
                        setDate(date)
                      }}
                      dateFormat='DD-MM-YYYY'
                    />
                  </FormControl>
                </GridItem>
                
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                    <FormControl variant="filled" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="filled-age-native-simple">Branch</InputLabel>
            <Select
              native
              value={branch}
              onChange={(event)=>{
                const value = event.target.value;
                setBranch(value)
              }}
              inputProps={{
                name: 'status',
                id: 'filled-age-native-simple',
              }}
            >
              <option aria-label="Select" value="All" >All</option>
              {
                branchDetail.map((br)=>{
                return <option value={br.BranchID}>{br.BranchName.split("MPL, ")[1]}</option>
                })
              }
            
            </Select>
          </FormControl>
                      </GridItem>
                 
                  <GridItem xs={6} sm={6} md={3} lg={3} style={{margin:'auto'}} >
                    
                  <Button variant="contained" color="primary" disableElevation
                   onClick={()=>{
                     
                    var vSearchStr={
                      "PIN":"",
                      "PhoneNo":"",
                      "PatientName":"",
                      "RegDateFrom":date.toISOString(),
                      "RegDateTo":date.toISOString(),
                      "vBranchID":branch=="All" ? "0":branch,
                      "AirlineID":0,
                      "Airline_Type":"KSA"
                  }
                     fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
                      method:"POST",
                      'Content-Type':"application/json",
                      body:JSON.stringify(vSearchStr)
                    }).then((res)=>res.json())
                     .then((result)=>{
                      var the_rows=[]
                     var srno=1
                    
                      result.map((data)=>{
                       console.log(data.Booking_Date)
                       var br_title=""
                       if(data.Collection_Point=="METROPOLE"){
                        br_title="Islamabad"
                      }else{
              
                     br_title=data.Collection_Point.split('MPL,')[1]
                      }
                      if(data.Collection_Point.toLowerCase().includes('genomic rwp') || data.Collection_Point.toLowerCase().includes('ddlc') || data.Collection_Point.toLowerCase().includes('biogene') || data.Collection_Point.toLowerCase().includes('device solutions') || data.Collection_Point.toLowerCase().includes('medikay') || data.Collection_Point.toLowerCase().includes('blue area') || data.Collection_Point.toLowerCase().includes('mpl-mhc')){
                        br_title="Islamabad"
                                  }
                                  if(data.Collection_Point.toLowerCase().includes('6th road rwp') || data.Collection_Point.toLowerCase().includes('falak air') || data.Collection_Point.toLowerCase().includes('abrar')  ){
                                    br_title="Rawalpindi"
                                  }
                        var obj={
                          srno:srno,
                        reportno:data.Report_No,
                        date:data.Booking_Date,
                    name:data.Passenger_Name,
                    passport:data.Passport_No,
                    cnic:data.CNIC, 
                    airline:data.Airline,
                    status:data.Report_Status ,
                    branch:br_title,
                    remarks:data.Remarks_IfAny
                        }
                        srno=srno+1
                       the_rows.push(obj)
                       
                      })
                      
                      setRows(the_rows)
                      setOrg_Rows(the_rows) 
                      getData(the_rows)              
                  })}}
                
                  >
                    Seach<SearchIcon />
                  </Button>
         
                  </GridItem>
                  <GridItem md={3} lg={3} style={{margin:'auto', float:'right'}} >
                  <ExcelFile element={ <Button
                color="primary"
                style={{backgroundColor:'green', color:"white"}}
               
              >
                Export Excel
              </Button> }>
                    <ExcelSheet dataSet={multiDataSet} name="KSA Summary"/>
                </ExcelFile>
                 
                    </GridItem>
              </GridContainer>
        
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                
                  return (
                
                      <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>
                  
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
    </Paper>
  );
}
