import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TablePagination from '@material-ui/core/TablePagination';

import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Detail from "./Detail";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as firebase from 'firebase';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

function createData(
  id,
  no_of_tests,
  status,
  date,
  amount,
  discount,
  payable,
  lab_tests,
  time,
  relation,
  relation_name,
  name
) {
  return {
    id,
    no_of_tests: lab_tests.length,
    status,
    relation,
    time,
    relation_name,
    name,
    date,
    amount,
    discount,
    payable,
    lab_tests
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  var status_color= props.row.status=='RHS' ? 'red' :
  props.row.status=='POW' ? 'yellow' : props.row.status=='SR' ? 'blue' : props.row.status=='DL' ? 'green' :'' 
  

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={props.key}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.booking_id}
        </TableCell>
        <TableCell scope="row" style={{backgroundColor:status_color,color:"white"}}>{row.status}</TableCell>
        <TableCell scope="row">{row.date}</TableCell>
        <TableCell scope="row">{row.lab_tests!=null ? Object.keys(row.lab_tests).length : 0}</TableCell>
        <TableCell scope="row">{row.total_price+" Rs"}</TableCell>
        <TableCell scope="row">{row.discount+" Rs"}</TableCell>
        <TableCell scope="row">{row.payable+" Rs"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* Details ka component call ho rha hai aur es ko pora props pass kiya ja rha hai */}
              <Detail data={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired
};


export default class History_Table extends React.Component {
  constructor(){
    super()
    this.state={
      rowsPerPage:10,
      page:0,
      rows:[ ],
      org_rows:[ ]

    }
  }
  componentWillMount(){
      fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLoginAuthorize',{
      method: 'POST',
      'Content-Type':"application/json",
      body:'vUID='+localStorage.getItem('patient_id')+'&vRole=Patient&vLoginType=ID&vRandom=21211'
    }).then((res)=>res.json()).then( async(res)=>{
      if (firebase.apps.length === 0) {
        firebase.initializeApp(res[0]);
    }
     const patient_id = localStorage.getItem('patient_id');
    firebase.database()
      .ref('patient/' + patient_id)
      .once('value', async data => {
        var json_res = data.toJSON();
        if(json_res!=null){
          var array = [];
          Object.keys(json_res).forEach(key => {
            var temp = json_res[key];
            array.push(temp);
          });
          var reports = array.reverse();
          console.log(reports)
          this.setState({rows:reports})
          this.setState({org_rows:reports})
          
        
        }
      })
    })
  }
  
  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:+event.target.value})
    this.setState({page:0})
  };
  render(){
  return (
    <Paper>
    <TableContainer >
       <div align="right">
               <TextField id="standard-basic" label="Search by Booking, Status"
               style={{width:'20em'}}
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value+""
                 
                  var data=await this.state.org_rows.map((item)=>{
                    var booking=item.booking_id+""
                    
                      if(booking.includes(text)){
                          lab_tests.push(item)
                      }else{

                      if(item.status.toLowerCase().includes(text.toLowerCase())){
                        lab_tests.push(item)
                      
                    }
                      }
                      return item
              
                  })
                  
                  Promise.all(data).then((item)=>{
                     this.setState({rows:lab_tests})
                  })
                  
            }}              
               />
               </div>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Booking ID</TableCell>
            <TableCell >Status</TableCell>
            <TableCell >Date</TableCell>
            <TableCell >No. of Tests</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell >Discount</TableCell>
            <TableCell >Payable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => (
          //  Row component hai es ko id and pori row as a props send kr rhe hn
           <Row key={row.booking_id} row={row} />
          ))}
        </TableBody>
      </Table>
     
    </TableContainer>
     <TablePagination
     rowsPerPageOptions={[10, 25, 100]}
     component="div"
     count={this.state.rows.length}
     rowsPerPage={this.state.rowsPerPage}
     page={this.state.page}
     onChangePage={this.handleChangePage}
     onChangeRowsPerPage={this.handleChangeRowsPerPage}
   />
   </Paper>
  );
}
}
