import React from "react";
 //nodejs library that concatenates classes
import classNames from "classnames";
 //@material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';


//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";

import BusinessIcon from '@material-ui/icons/LocationCity';
import EmailIcon from '@material-ui/icons/Email';


import CallIcon from '@material-ui/icons/Call';





 //@material-ui/icons

 //core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

 import team1 from "assets/img_stock/FutureScientific/testimage.jpeg";







const useStyles = makeStyles(styles);

export default function   ProductSectionDetails() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      {width<500?
      <h2 className={classes.title} style={{color:"#3e3e8a"}}>About Us</h2>
:
<h2 className={classes.title} style={{color:"#3e3e8a"}}>About Us</h2>

      }
      <div>
        <GridContainer style={{display:"flex",justifyContent:"center",alignIttem:"center",}}>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"center",flexDirection:"column",paddingBottom:250}}>
         
         {/* <br/> */}
         <div style={{width:width<500?"100%":width<950?"100%":"100%",display:"flex",justifyContent:"center",flexDirection:"column"}} >
         {/* <h2 className={classes.title} style={{}}>CEO's Message</h2> */}
         <h5 className={classes.description} style={{textAlign:"justify",color:"black",marginLeft:width<950?"0%":"0%",}}>
         Since 2007, Future Scientific has been providing superior quality laboratory equipment and testing kits to its customers in Pakistan.Future Scientific was initially established in the twin city of Rawalpindi & Islamabad and now we have a nationwide network with branch offices in major cities across the country.  FS is committed to deliver unmatched sales & technical support  services with a vision to position Future Scientific as a leader to introduce the new analytical technologies and innovative testing in Pakistan. To comply with Medical Devices Rules 2017 made under the DRAP Act 2012 of Pakistan, Future Scientific is a licensed establishment from Drug Regulatory Authority of Pakistan (DRAP) Licence # ELI.00209. In an endeavor to fulfill our promise of a quality service to our customers, Future Scientific has established, implemented, maintains and continually improves a Quality Management System that conforms to the requirements of ISO 9001:2015.    
         </h5>
         </div>
         {/* <h2 className={classes.title}>Vision</h2>
         <h5 className={classes.description} style={{textAlign:"justify",color:"black"}}>
         At Metropole, our vision is to be the premier and most credible diagnostic service provider in
Pakistan, recognized for offering reliable, accessible and affordable services, to contribute in
disease prevention through precise and prompt diagnosis, ultimately leading to build a healthier
nation.
         </h5> */}
       </GridItem>
       {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%',display:"flex",flexDirection:"column"}}>
        <div  style={{height:'50%',display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
         <img
               src={require("assets/img_stock/FutureScientific/testimage.jpeg")}
               style={{ 
               maxWidth:width<500?"100%": "80%",
               height: "auto",
               borderRadius: "calc(.25rem - 1px)",
               boxShadow:
               "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
          
           }}
             />
            



             </div>
         </GridItem> */}
                 {/* <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
         
                
                    <div style={{width:width<500?"100%":width<950?"100%":"100%",display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h2 className={classes.title} style={{color:"#3e3e8a"}}>CEO's Message</h2>
                    <h5 className={classes.description} style={{textAlign:"justify",color:"black",marginLeft:width<950?"0%":"0%"}}>
         Future Scientific (FS) is providing superior quality laboratory equipment and testing kits to its customers in Pakistan.

FS is committed to deliver unmatched sales, engineering and application support services with a vision is to position Future Scientific as a leader to introduce the new analytical technologies especially in Point of Care (POC) and innovative testing in Pakistan. FS was established in Year 2005 initially in twin cities, Rawalpindi & Islamabad, and now with branch offices at major cities Lahore, Karachi & Peshawar.
         </h5>
                    </div>
                  
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%',display:"flex",flexDirection:"column"}}>
                   <div  style={{height:'50%',display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                    <img
                          src={require("assets/img_stock/FutureScientific/testimage.jpeg")}
                          style={{ 
                          maxWidth:width<500?"100%": "80%",
                          height: "auto",
                          borderRadius: "calc(.25rem - 1px)",
                          boxShadow:
                          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                     
                      }}
                        />
                       
         
         
         
                        </div>
                    </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
       
         <div style={{width:width<500?"100%":width<950?"100%":"100%",display:"flex",justifyContent:"center",flexDirection:"column"}} >
         <h2 className={classes.title} style={{color:"#3e3e8a"}}>Our Team</h2>
         <h5 className={classes.description} style={{textAlign:"justify",color:"black",marginLeft:width<950?"0%":"0%"}}>
Future Scientific (FS) is providing superior quality laboratory equipment and testing kits to its customers in Pakistan.

FS is committed to deliver unmatched sales, engineering and application support services with a vision is to position Future Scientific as a leader to introduce the new analytical technologies especially in Point of Care (POC) and innovative testing in Pakistan. FS was established in Year 2005 initially in twin cities, Rawalpindi & Islamabad, and now with branch offices at major cities Lahore, Karachi & Peshawar.
</h5>
         </div>
        
       </GridItem> */}
       {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%',display:"flex",flexDirection:"column"}}>
        <div  style={{height:'50%',display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
         <img
               src={require("assets/img_stock/FutureScientific/testimage.jpeg")}
               style={{ 
               maxWidth:width<500?"100%": "80%",
               height: "auto",
               borderRadius: "calc(.25rem - 1px)",
               boxShadow:
               "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
          
           }}
             />
            



             </div>
         </GridItem> */}
          {/* minHeight:"400px", add before eid day 27-jun-2023 Dowload Brochure button opr niche hai es k lye same size ki image dalni hai */}
          {/* <GridItem xs={12} sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignIttem:"center",}}>
            
               
                  <div
                style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column",}}>


                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20}}>
              
              <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon>
              <div style={{display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="tel:0514577810">Office Number </a>
              <a style={{color:"black"}} href="tel:0514577810">+92-51-4577810</a>

             

              </div>
          

             
              </div>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%"}}>
              
                <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon>
                <div style={{display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column"}}>
                <a style={{color:"#3e3e8a"}} href="tel:03201231014">Cell Number</a>

                <a style={{color:"black"}} href="tel:03201231014">+92-3201231014</a>
                
                </div>
              

               
                </div>
            
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%"}}>
              
              <CallIcon style={{width:width<500?30:40,height:width<500?30:40,color:"#3e3e8a",}}></CallIcon>
              <div style={{display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="tel:0514478510">Fax Number</a>

              <a style={{color:"black"}} href="tel:0514478510"> +92-51-4478510</a>


              </div>
              
     

             
              </div>

              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%"}}>
              
              <EmailIcon style={{width:width<500?30:50,height:width<500?30:50,color:"#3e3e8a",}}></EmailIcon>

              <div style={{display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="mailto:info@futurescientific.com.pk">Email Address</a>
{width<500?
              <a style={{color:"black"}} href="mailto:info@futurescientific.com.pk">info@futurescientific.com<br></br>.pk</a>
:
<a style={{color:"black"}} href="mailto:info@futurescientific.com.pk">info@futurescientific.com.pk</a>

}



              </div>
              
     

             
              </div>
              
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginTop:"3%"}}>
              
              <BusinessIcon style={{width:width<500?30:50,height:width<500?30:50,color:"#3e3e8a",}}></BusinessIcon>


              <div style={{display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column"}}>
              <a style={{color:"#3e3e8a"}} href="mailto:info@futurescientific.com.pk">Address</a>

              <a style={{color:"black"}} >FS House, Opposite Street No 04, Main Road,Gangaal West, </a>
              




              </div>
            
              </div>
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,marginLeft:"8%"}}>
            
              <a style={{color:"black"}} > Shaheen Town, Rawalpindi, Punjab,Pakistan</a>
             
              </div>
              
      <h2 className={classes.title} style={{color:"black"}}>Social Media Links</h2>

              <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",color:"black",fontWeight:"bold",fontSize:width<380?20:width<415?25:width<460?25:width<550?30:width<770?40:20,}}>
              
            
<a style={{color:"#3e3e8a"}} href="https:www.linkedin.com/company/futurescientific/">
<LinkedInIcon  style={{width:70,height:70,color:"#3e3e8a"}}></LinkedInIcon>
         </a>
         <a style={{color:"#3e3e8a"}} href="https:www.facebook.com/www.futurescientific.com.pk?mibextid=9R9pXO">
<FacebookIcon style={{width:70,height:70,color:"#3e3e8a"}}></FacebookIcon>
</a>
   
              </div>
              
                
              
                </div>
               
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignIttem:"center",marginTop:width<500?"10%":""}}>
          <div
                style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"start",flexDirection:"column",}}>


         
        <iframe src="https:www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.798494683826!2d73.12059897515762!3d33.61053244092565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb340fba6ffb%3A0x3905f8dcd40f6fd9!2sFuture%20Scientific!5e0!3m2!1sen!2s!4v1703332786267!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              
                
              
                </div>
</GridItem> */}
        
         </GridContainer>
      </div>
    </div>
  );
}
