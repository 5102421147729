import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  outer: {
    display: "table",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%"
  },
  middle: {
    display: "table-cell",
    verticalAlign: "middle"
  },
  inner: {
    width: "400px"
    /*whatever width you want*/
  }
}));

export default function Detail(props) {
  const classes = useStyles();
  const theme = useTheme();
  var counter = 0;
  var status_color= props.data.status=='RHS' ? 'red' :
  props.data.status=='POW' ? 'yellow' : props.data.status=='SR' ? 'blue' : props.data.status=='DL' ? 'green' :'' 
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Booking No
          </Typography>
          <Typography variant="h5" component="h2">
            {props.data.booking_id}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Name
          </Typography>
          <Typography variant="body2" component="p">
            {props.data.name}
          </Typography>
          {props.data.relation_name != '' ? (
            <>
              <Typography className={classes.pos} color="textSecondary">
                Booking For
              </Typography>
              <Typography variant="body2" component="p">
                {props.data.relation_name}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Typography className={classes.pos} color="textSecondary">
            Relation
          </Typography>
          <Typography variant="body2" component="p">
            {props.data.relation}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Date
          </Typography>
          <Typography variant="body2" component="p">
            {props.data.date}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Time
          </Typography>
          <Typography variant="body2" component="p">
            {props.data.time}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Address
          </Typography>
          <Typography variant="body2" component="p">
            {props.data.address}
          </Typography>
          
          {props.data.lab_tests != null ? (
            <Typography className={classes.pos} color="textSecondary">
              Booked Lab Test
            </Typography>
          ) : (
            <></>
          )}
          {props.data.lab_tests != null ? 
            Object.keys(props.data.lab_tests).map((key)=>{
                var test= props.data.lab_tests[key]
                console.log(test)
                counter = counter + 1;
              return (
                <Typography variant="body2" component="p" key={counter}>
                  {counter + "- " + test['Test_Name']}
                </Typography>
              );
            })
           : (
            <></>
          )}
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>{props.data.total_price} Rs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell>{props.data.discount} Rs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payable</TableCell>
                <TableCell>{props.data.payable} Rs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </div>
    
      <div
        style={{
          backgroundColor: status_color,
          width: "10em",
          float: "right",
          display: "table-cell",
          verticalAlign: "middle"
        }}
      >
        <div
          style={{
            left: 0,
            right: 0,
            top: 0,
            textAlign: "center",
            bottom: 0,
            margin: "auto",
            width: "100%",
            height: "100%",
            overflow: "auto"
          }}
        >
          <div className={classes.outer}>
            <p style={{ color: "white" }}>STATUS</p>
        <h1 style={{ color: "white" }}>{props.data.status}</h1>
          </div>
        </div>
      </div>
    </Card>
  );
}
