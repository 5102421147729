/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink,useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import url from '../../link'
// import MplProfile from "../../assets/pdf/MplProfileNew.pdf"
// import AutoimmuneDiagnostics from "../../assets/pdf/AutoimmuneFlyer.pdf"
// import Echoflyer from "../../assets/pdf/Echoflyer.pdf"
// import Fibroscan from "../../assets/pdf/FibroscanFlyer.pdf"
// import OPG from "../../assets/pdf/OPGFlyer.pdf"


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  

  return (
    <List className={classes.list}>
      
      {/* Home for all Header that are honePage in index.js*/}
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/'); 
        }}
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home </NavLink>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/AboutUs'); 
        }}
        >
          <NavLink to="/AboutUs" 
          style={{color:'white'}}
          >About Us </NavLink>
        </Button>
      </ListItem>
      
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/Products'); 
        }}
          
        >
          <NavLink to="/Products" 
          style={{color:'white'}}
          >Products</NavLink>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/Principles'); 
        }}
        >
          <NavLink to="/Principles" 
          style={{color:'white'}}
          >Principles</NavLink>
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Quality Policy </NavLink>
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#3d3d8a', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/ContactUs'); 
        }}
        >
          <NavLink to="/ContactUs" 
          style={{color:'white'}}
          >Contact Us </NavLink>
        </Button>
      </ListItem>
      
      
        {/* Home for all Header that are honePage in index.js*/}
       

{/* 2-sep-2023 About us Patient ko b nazar aae */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem>
      :null
      } */}
      {/* <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Our Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem> */}
    
      {/* NewsAndFeeds 31-dec-2022 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90',
          // boxShadow:" 5px 5px #cbcccd"
          // boxShadow:" 5px 5px #faa931"

        }}
          
        >
          <NavLink to="/NewsAndFeed" 
          style={{color:'white'}}
          >News & Feed </NavLink>
        </Button>
      </ListItem> */}

      {/* Printing 08-march-2023 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90'}}
          
        >
          <NavLink to="/Printing" 
          style={{color:'white'}}
          >Print </NavLink>
        </Button>
      </ListItem> */}
     {/* Service part in Header that are drop down when we click its move to that screen define in index.js "/Clinic_Chemistry&Immunology" etc */}
     
      {/* <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center',}}>
        <CustomDropdown
          buttonText="SERVICES"
          style={{backgroundColor:'#196c90',
        }}
          className={classes.navLink}
      
          dropdownList={[
            <NavLink to="/Clinic_Chemistry&Immunology" className={classes.dropdownLink}>
   Clinical Chemistry & Immunology
            </NavLink>,
            <NavLink to="/Hematology" className={classes.dropdownLink}>
            Hematology
                         </NavLink>,
            
              
              <NavLink to="/Microbiology&ClinicalPathology" className={classes.dropdownLink}>
                 Microbiology & Clinical Pathology

                </NavLink>,
                  <NavLink to="/Molecular" className={classes.dropdownLink}>
                  Molecular Pathology & Genetics
                    </NavLink>,
              <NavLink to="/Histopathology" className={classes.dropdownLink}>
                     
Histopathology

                        </NavLink>,
            
                        <NavLink to="/Special_Pathology" className={classes.dropdownLink}>
                        Special Pathology
                          </NavLink>,
                           <NavLink to="/Ultrasound" className={classes.dropdownLink}>
                           Ultrasound
                                        </NavLink>,
                                         <NavLink to="/Echocardiography" className={classes.dropdownLink}>
                                        Echocardiography
                                                      </NavLink>,
                                        <NavLink to="/Fibroscan" className={classes.dropdownLink}>
                                        Fibroscan
                                        </NavLink>,
                                        <NavLink to="/DigitalXray" className={classes.dropdownLink}>
                                        Digital X-Ray
                                        </NavLink>,
                                        <NavLink to="/OPG" className={classes.dropdownLink}>
                                        OPG (Orthopantomogram)
                                        </NavLink>,
                                        <NavLink to="/Spirometry" className={classes.dropdownLink}>
                                        Spirometry 
                                        </NavLink>,
                        //  <NavLink to="/Covid" className={classes.dropdownLink}>
                        // Covid 19 Molecular Lab
                        // </NavLink>,




          ]}
        />
      </ListItem> */}
   
      {/* if login person are not patient then show */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
            <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
   Who We Are
            </NavLink>,
             <NavLink to="/OurMission" className={classes.dropdownLink}>
Mission & Vision
             </NavLink>
              

          ]}
        />
      </ListItem>
      :null
      } */}
       {/* if login person are  patient then show patient Id and passworn on diary */}
       {/* 2-sep-2023 ko header se remove kiya */}
      {/* {
        localStorage.getItem('role')=='Patient' ?
        <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        
        <NavLink to="/COVID19_HomeSampling" 

        style={{color:'white'}}
      

      >Book COVID-19 Home Sampling</NavLink>
      </Button>
    </ListItem>
      : null
      } */}
      {/* onLine Reports me login ni hona chahaye when click on this go to Login page */}
      {/* {(localStorage.getItem('patient')==null && localStorage.getItem('emirates')==null && localStorage.getItem('qatar')==null && localStorage.getItem('flydubai')==null && localStorage.getItem('onix')==null) && localStorage.getItem('airblue')==null && localStorage.getItem('etihad')==null && localStorage.getItem('OmanAir')==null && localStorage.getItem('SereneAir')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        <NavLink to="/Login" 

        style={{color:'white',
        
      }}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    } */}
    {/* if localStorage have emirates then only show 3 main menu item */}
       
       
     
  
  
   
      
      
      {/* <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="MENU"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
          <NavLink to="/Quotation" className={classes.dropdownLink}>
Get a Quote
</NavLink>,

        ]}
        // get a qoute k nichy se cut kiye hn 
//         <NavLink to="/QatarLogin" className={classes.dropdownLink}>
// Qatar Airways
// </NavLink>,
// <NavLink to="/Emirates" className={classes.dropdownLink}>
// Emirates
// </NavLink>,
// <NavLink to="/FlyDubai" className={classes.dropdownLink}>
// FlyDubai
// </NavLink>,
// <NavLink to="/AirBlue" className={classes.dropdownLink}>
// Air Blue
// </NavLink>,
// <NavLink to="/SereneAir" className={classes.dropdownLink}>
// Serene Air
// </NavLink>,
// <NavLink to="/OmanAir" className={classes.dropdownLink}>
// Oman Air
// </NavLink>,
// <NavLink to="/Onix_Enterprises" className={classes.dropdownLink}>
// Onix Enterprises
// </NavLink>
      />
    </ListItem>
       */}
      
       
        {/* NewsAndFeeds 31-dec-2022 */}
      
       
      
      
    </List>
  );
}
