import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import useWindowDimensions from "../../useWindowDimensions";
// import TeamSection from "../../views/";
import TeamSection from "./ProductSectionDetails";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { BorderColor } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.section} style={{padding:"0px 0px",paddingBottom:"15%"}}>
      <TeamSection></TeamSection>
    {/* <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"2%"}}>
      <h2 className={classes.title} style={{marginTop:"0px"}}>TEAM OF PATHOLOGISTS </h2>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/01.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120
            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>COL. RETD. PROF DR. WAQAR ALI</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, Dip. Path. (AFPGMI)</p>
                 <p   style={{color:'black',marginTop:-10}}>FCPS Hematology</p>
                 <p   style={{color:'black',marginTop:-10}}>Chief Pathologist & Consultant Hematologist</p>


                 

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/02.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. AMNA AFZAL</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, DipRCPath Microbiology</p>
                 <p   style={{color:'black',marginTop:-10}}>FCPS Microbiology</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Microbiology</p>


                 

</div>
          </GridItem>    <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/03.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. FARZANA KHALID</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, DCP, FCPS</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Histo & Cytopathologist</p>

                 

</div>
          </GridItem>  
            <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/04.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. M. ISHAQUE CHAUHAN</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, FCPS</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Histopathologist</p>
</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/05.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. HANI AKBAR RAO</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, MS(Genetics) UK</p>
                 <p   style={{color:'black',marginTop:-10}}>Clinical Geneticist</p>

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/06.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. M. ARIF N. SAQIB</p>
                 <p   style={{color:'black',marginTop:-10}}>PhD Molecular Biology</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Molecular Biologist</p>
                 


                 

</div>
          </GridItem>
      
    
        
      </GridContainer> */}
      {/* 2nd part */}
      {/* <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"2%"}}>
      <h2 className={classes.title}>TEAM OF RADIOLOGISTS </h2>

            
             
          
           

          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/07.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

                
            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. IRAM IQBAL</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, FCPS</p>
                 <p   style={{color:'black',marginTop:-10}}>Associate Professor</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Radiologist</p>


                 

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/08.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. AYESHA JAVAID</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, MCPS</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Radiologist</p>
                 

</div>
          </GridItem>    <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/09.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. AGHA MUHAMMAD SAMI</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, MCPS</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Radiologist</p>
                 

</div>
          </GridItem>    <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/10.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>DR. SHAH HAMAYUN</p>
                 <p   style={{color:'black',marginTop:-10}}>MBBS, FCPS Cardiology</p>
                 <p   style={{color:'black',marginTop:-10}}>Consultant Cardiologist</p>

                 

</div>
          </GridItem>
      
    
        
      </GridContainer> */}
       {/* 3rd part */}
       {/* <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"2%"}}>
      <h2 className={classes.title}>TECHNICAL & QMS TEAM </h2>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/11.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>Dr Najam Farooq, Ph.D</p>
                 <p   style={{color:'black',marginTop:-10}}>BS MLT, Ph.D Biotechnology</p>
                 

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/12.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>Dr Ishtiaq Ahmed, Ph.D</p>
                 <p   style={{color:'black',marginTop:-10}}>BS MLT, Ph.D Biochemistry</p>
                 

</div>
          </GridItem>    <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/13.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>Nadeem Ahmed Tarar</p>
                 <p   style={{color:'black',marginTop:-10}}>BS MLT, M.Phil Biochemistry</p>
                 

</div>
          </GridItem>    <GridItem xs={12} sm={6} md={3} lg={3} style={{display:"flex", justifyContent:"center",alignItem:"center"}}>
          <div style={{height:"75%",width:"75%"}}>

         <img
                src={require("assets/img_stock/New2023/PersonImage/14.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 <p   style={{color:'#196c90',marginTop:10,textAlign:"center",fontWeight:"bold"}}>Asim Ansari</p>
                 <p   style={{color:'black',marginTop:-10}}>BS MLT, M.Phil Biotechnology</p>
                 

</div>
          </GridItem>
      
    
        
      </GridContainer> */}
     
     
    </div>
  );
}
