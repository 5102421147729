import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} >
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={12}>
          {/* <h2 className={classes.title} style={{marginTop:"-20px"}}>Who We Are</h2> */}
          <h5 className={classes.description} style={{textAlign:"justify",color:"black",marginTop:"-10px"}}>
          We, Metropole Laboratories Private Ltd, own an advanced diagnostic facility comprised of >10,000
square feet area in the Capital city with a growing network of our service centers. We own regulatory
licenses from Islamabad Healthcare Regulatory Authority, Islamabad (IHRA) and Pakistan Nuclear
Regulatory Authority (PNRA). We take pride in being the only diagnostic company in Pakistan to
achieve the prestigious ISO-15189 accreditation from the Pakistan National Accreditation Council
(PNAC) within a short span of time since our launch. We also hold the ISO 9001:2015 certification
which reinforces our commitment to continuous improvement across all aspects of our operations.
We continuously invest in offering world's leading diagnostic technologies in our core laboratory,
radiology and imaging facilities. Our dedicated and exceptionally qualified team of consultants,
scientists & allied healthcare professionals ensure constant compliance with global healthcare
standards. We participate in internationally monitored external quality assurance programs along
with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we
provide. Our customized LMIS with features like QR-based robotic pre-analytical management,
bidirectional interfacing of analyzers, KPI monitoring dashboards, and closely monitored & constant
feedback system makes our workflows swifter & smarter. We are committed to provide prompt,
efficient, and patient-centered services, positioning ourselves as the premier diagnostic service
provider for better healthcare management of patients and clients alike.
          </h5>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%'}}>
          <img
                src={require("assets/img_stock/first_floor.JPG")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem> */}
      </GridContainer>
     
     
    </div>
  );
}
