import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../assets/img/mpl-logo.png'
import footer from '../../../assets/img/mpl-footer.png'

const styles = StyleSheet.create({
    page: { 
        paddingTop:150,
        paddingBottom:50,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    
    logo: {
        width: 180,
        height: 100,
        marginLeft:150
    },
    barcode: {
        width: 150,
        height: 40
    }
  });
  const date=new Date()
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const Invoice=({invoice})=> (
    <Document>
      <Page style={styles.page} size="A4" wrap={true}
      
    
      >
      <View fixed style={{
                width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                paddingLeft: 10,
                alignItems:"center",
                paddingRight: 10,
                paddingTop: 10
            }} >
               <View style={{display: 'flex', flexDirection: 'column' , marginLeft:10}}>
                  <Text style={{ 
                    fontFamily:'Helvetica-Bold',
                    fontSize:7
                    }}>Date</Text>
                  <Text style={{fontFamily:'Helvetica', color:'grey'}}>{date.ddmmyyy()}</Text>
                  <Text style={{fontFamily:'Helvetica-Bold', fontSize:7}}>Time</Text>
                  <Text style={{fontFamily:'Helvetica', color:'grey'}}>{time}</Text>
                </View>
                <Image style={styles.logo} src={logo} />
               
               
            </View>

            <View style={{width:'100%',paddingRight:10, paddingLeft:10}} 
           
           >
             <InvoiceTitle title={"Sample Tracking System"}/>
               <InvoiceItemsTable invoice={invoice} />
           </View>



        
      </Page>
    </Document>
  );
  
export default Invoice