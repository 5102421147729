import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';


// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// import COVID19_PCR from '../../COVID19_PCR/Covid19_PCR.js'
// import Emirates from './AirLineSection.js'
// 26-june-2023
import AQ from "../../../assets/img_stock/New2023/icon/Icon1.png"
// import CP from "../../../assets/img_stock/New2023/icon/Icon3.png"
// import GSS from "../../../assets/img_stock/New2023/icon/Icon2.png"
import IL from "../../../assets/img_stock/New2023/icon/Icon4.png"
import AD from "../../../assets/img_stock/New2023/icon/Icon5.png"
import PCS from "../../../assets/img_stock/New2023/icon/Icon6.png"
//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";









import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.section} style={{padding:width<500?"0%":"10px 0"}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12} style={{}}>
          
          {/* <h2 className={classes.title}>Metropole Laboratories (Pvt) Ltd.</h2> */}
        
          <p className={classes.description} style={{fontSize:16,textAlign:"justify",fontWeight:"bold",marginLeft:width<500?"8%":"20%",marginRight:width<500?"8%":"20%",color:"#3C4858",marginTop:50}}>
          {/* <span style={{fontWeight:"bolder",fontSize:18,color:"#3d3d8a"}}>
             Future Scientific </span>is providing superior quality laboratory equipment and testing kits to its customers in Pakistan.
FS is committed to deliver unmatched sales, engineering and application support services with a vision is to position Future Scientific as a leader to introduce the new analytical technologies especially in Point of Care (POC) and innovative testing in Pakistan. FS was established in Year 2005 initially in twin cities, Rawalpindi & Islamabad, and now with branch offices at major cities Lahore, Karachi & Peshawar. */}
{/* <span style={{fontWeight:"bolder",fontSize:18,color:"#3d3d8a"}}>Future Scientific</span> is a leading diagnostic solution provider in Pakistan. We provide superior quality Laboratory analysers & Testing kits to our customers across the country.  Our dedicated team of Sales & Technical support is our key strength that makes us distinguished in the diagnostic industry of the Pakistan.  */}
<span style={{fontWeight:"bolder",fontSize:18,color:"#3d3d8a"}}>Future Scientific</span> is a leading diagnostic solution provider in Pakistan. We provide superior quality Laboratory analysers & Testing Kits to our customers across the country. Our dedicated team of Sales & Technical support is our key strength that makes us distinguished in the diagnostic industry of the Pakistan. 

</p>
       
        </GridItem>
        </GridContainer>
       
            {/* <COVID19_PCR /> */}

     
      
     {/* <br /> */}

        <GridContainer style={{marginLeft:"1%",marginRight:"1%"}} >
       
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <InfoArea
              title="Vision"
              description="Our vision is to closely analyse the emerging diagnostic needs in the country and to be the leading solution provider of innovative & modern diagnostic products.to our customers. "
              icon={IL}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <InfoArea
              title="Mission"
              description="Our mission is the utmost satisfaction of our clients by providing state of the Art diagnostic technologies. FS strives to achieve the ultimate reliability and efficiency in services to sustain customer’s loyalty and focus on long term relationships."
              icon={AD}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12}  md={6} lg={3}>
            <InfoArea
              title="Regulatory Compliance "
              description="To comply with Medical Devices Rules 2017 made under the DRAP Act 2012 of Pakistan, Future Scientific is a licensed establishment from Drug Regulatory Authority of Pakistan (DRAP) 
              Licence # ELI.00209"
              icon={PCS}
              iconColor="success"
              vertical
            />
          </GridItem>
         
         
          {/* <GridItem xs={12} sm={12} md={6} lg={4}>
            <InfoArea
              title="Mission Statement of Company"
              description="Our mission is the utmost satisfaction of our clients by providing state of the art innovative equipment, diagnostic kits and consumables. FS strives to achieve the ultimate reliability and efficiency in services to sustain customer’s loyalty and focus on long term relationships. "

              icon={GSS}
              iconColor="success"
              vertical
            />
          </GridItem> */}
          <GridItem xs={12} sm={12}  md={6} lg={3}>
            <InfoArea
              title="Quality Policy"
              description="In an endeavor to fulfill our promise of a quality service to our customers, Future Scientific has established, implemented, maintains and continually improves a Quality Management System that conforms to the requirements of  ISO 9001:2015. "

              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="FS Soft"
              description="FS Soft is developed by experts of Future Scientific with an experience of more than a decade in the Clinical Diagnostic Laboratory and in software development, which is covering the domain of Laboratory Information Management System and Hospital Information Management System."
              icon={CP}
              iconColor="danger"
              vertical
            />
          </GridItem> */}
         
          
        
        </GridContainer>

        <br />
        {/* aik me he kr dya hai phly 3 ,3 alag thy now 6 in one */}
        {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Assured Quality"
              description="We participate in internationally monitored external quality assurance programs along with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we provide."
              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Competitive Prices"
              description="MPL offers exceptional quality of services at competitive and affordable prices which are usually 20 to 25% lesser from other diagnostic service providers, offering best value against the fee you pay."
              icon={CP}
              iconColor="danger"
              vertical
            />
          </GridItem>
         
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Patient-Centered Services "
              description="We are dedicated to earning the trust and loyalty of our patients by prioritizing their comfort and satisfaction. Our passion for ensuring that every patient receives the highest quality of care and support drives us to continuously strive for excellence."
              icon={PCS}
              iconColor="success"
              vertical
            />
          </GridItem>
        
        </GridContainer> */}
         {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%',marginTop:"5%"}}>
          <img
                src={require("assets/img/building.jpeg")}
                style={{ 
                maxWidth: "60%",
                maxHeight:'30%',
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer> */}
     
    </div>
  );
}
