import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Lab_Tests from "./Lab_Tests"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';
import useWindowDimensions from "../../useWindowDimensions";


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { ...rest } = props;
  const [reports,setReports]=React.useState('')
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
      if(localStorage.getItem('patient')!='true'){
       window.location.href="https://mpl-labs.pk"
      }else{
        setReports('true')
      }
      if(localStorage.getItem('role')=="Admin"){
        var vSearchStr={
          "User_ID" : localStorage.getItem("user_id"),
          "Menu_Option" :"WebPortal_Reporting"
      }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
          method:"POST",
          'Content-Type':"application/json",
          body: JSON.stringify(vSearchStr)
        }).then(res=>res.json()).then((res)=>{
          console.log(res)
          if(res[0].Option_Status=="Authorized"){
           
          }else{
            localStorage.clear()
            window.location.href = "https://mpl-labs.pk/Login"
          }
        })
      }
},[])
      

  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <div  >
         <img
                src={require("assets/img_stock/New2023/backgroundFinal7.jpg")}
                style={{ 
                maxWidth: "100%",
                height:width<500?200 : "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                marginTop:width<500?"22%":"8%",
                opacity:width<500?0.5:0.5
            
            }}
              />
              {/* <h1 className={classes.title} style={{position:"absolute"}}>Message From CEO </h1> */}

        {/* <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title} style={{marginTop:-40}}>Message From CEO </h1>
              
            
            
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
      {/* <Parallax filter image={require("assets/img/info.jpeg")} style={{marginTop:width<500?90:100}}
>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
        
      
       
           
              <h1 className={classes.title} style={{marginTop:-20}} >Lab Reports</h1>
            
             
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      {/* <div className={classNames(classes.main ,classes.mainRaised)} style={{marginTop:"-150px"}} > */}
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-180:-60}}> */}
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-50:-200}}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-50:width<950?-50:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em",}} >
       
          {/* <br /> */}
          <img src={report} style={{
  width: '7em',}} class="center"/>
   <h3 style={{color:"black",fontWeight:"bold"}} >
   Lab Reports
              </h3>
  {/* reports oper true hua useEffect me now lAB_Test component call  */}
          {reports=='true' ?   <Lab_Tests /> :<></>}
            {/* <FreeHS_Msg /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
