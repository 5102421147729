import React,{ Component } from 'react';


import MaterialTable from 'material-table';
import 'whatwg-fetch';

import ReactLoading from 'react-loading';

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
export default class Tracking extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            columns: [
                { title: 'Tracking ID', field: 'tracking_id',  defaultSort:'asc'},
                { title: 'Sender', field: 'sender'},
                { title: 'Car No', field: 'car_no' },
                { title: 'Driver No', field: 'driver_no'},
                { title: 'Branch', field: 'branch'},
                { title: 'Arrival Date', field: 'arrival_date' },
                { title: 'Arrival Time', field: 'arrival_time'},
                { title: 'Booking Date', field: 'booking_date' },
                { title: 'Booking Time', field: 'booking_time'},
                { title: 'Total Sample', field: 'total_sample' },
                { title: 'Urgent Sample', field: 'urgent_sample'},
                { title: 'Receiver', field: 'receiver'},
                { title: 'Destination', field: 'destination'}
              ],
              data: [],
              loading:true
                
        }
        this.getData=this.getData.bind(this)
    }
    
    getData() {
        var columns = this.state.columns;
        var rows = this.state.data;
        return { columns, rows};
      }
      

      componentWillMount(){
        var name=localStorage.getItem('patient_name').toLowerCase().split('mpl-')[1]
        console.log(name)
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then( async(response)=>{
        var branch_name=""    
        var temp=await response.map((item)=>{
            console.log(item)
                if(item.BranchName.toLowerCase().includes(name)){
                  branch_name=name
                }
              })
             
              Promise.all(temp).then(()=>{
                if(branch_name==""){
                    branch_name="branch"
                }
                fetch("https://mpl-labs.felpower.com.pk/getBranchWise?branch="+branch_name
                ).then((res)=>res.json())
                 .then((result)=>{
                     var the_rows=[]
                   result.map((item)=>{
                       var obj={
                        tracking_id:item.tracking_id,
                        sender:item.sender,
                        car_no:item.car_no,
                        driver_no:item.driver_no,
                        branch:item.branch,
                        arrival_date:new Date(item.arrival_date).ddmmyyy(),
                        arrival_time:item.arrival_time,
                        booking_date:new Date(item.booking_date).ddmmyyy(),
                        booking_time:item.booking_time,
                        total_sample:item.total_sample,
                        urgent_sample:item.urgent_sample,
                        receiver:item.receiver==null ? '' : item.receiver,
                        destination:item.destination==null ? '' : item.destination
                       }
                       the_rows.push(obj)
                   })
                   this.setState({data:the_rows})
                   this.setState({loading:false})
                 })
              })
          })
       
      }

    render(){
        return (
          <>
{this.state.loading ?
<center>
  <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
  </center>
  :
  <MaterialTable
      title="Record of Sample Tracking"
      columns={this.state.columns}
      data={this.state.data}
    
  
          />
}
</>
            
        )
    }
}