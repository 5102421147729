import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// import Button from "components/CustomButtons/Button.js";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";



// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// import team1 from "assets/img_stock/bio_chem_1.JPG";
// import P1 from "assets/img_stock/FutureScientific/Products/New/chorus.jpg";
import P1 from "assets/img_stock/FutureScientific/Products/New2/Chorus.jpg";

import P1Brocher from "assets/img_stock/FutureScientific/Products/chorusBroucher.pdf";
import P6 from "assets/img_stock/FutureScientific/Products/New2/autoDot.jpeg";
import P6AutoDotBroucher from "assets/img_stock/FutureScientific/Products/auto-dat-broucher.pdf";



// import P2 from "assets/img_stock/FutureScientific/Products/New/11.jpg";
import P3 from "assets/img_stock/FutureScientific/Products/New2/cube30.jpeg";
import P3Broucher from "assets/img_stock/FutureScientific/Products/cube_30touchbrochure.pdf";


import P4 from "assets/img_stock/FutureScientific/Products/New2/miniCube.jpeg";
import P4MiniCubeBrochure from "assets/img_stock/FutureScientific/Products/mini-cubebrochure.pdf";

import P5 from "assets/img_stock/FutureScientific/Products/New2/blueDiver.jpeg";
import P5BlueDiverBroucher from "assets/img_stock/FutureScientific/Products/blueDiverBrocher.pdf";


import P7 from "assets/img_stock/FutureScientific/Products/New2/helfan.jpeg";
import P8 from "assets/img_stock/FutureScientific/Products/New2/helfanhydroge.jpeg";
import P8hELIFANBreath from "assets/img_stock/FutureScientific/Products/Brochure single pages.pdf";

import P9 from "assets/img_stock/FutureScientific/Products/New2/LipoPrint.jpeg";
import P9LipoPrintBroucher from "assets/img_stock/FutureScientific/Products/Lipoprint System Brochure.pdf";

import P10 from "assets/img_stock/FutureScientific/Products/New2/Immundiagnostik.jpeg";
import Immundiagnostik from "assets/img_stock/FutureScientific/Products/Immundiadnostic.pdf";


import P11 from "assets/img_stock/FutureScientific/Products/New2/Demeditec.jpeg";
import P10ProductCatalogForImmundiagnostic from "assets/img_stock/FutureScientific/Products/Demeditec and immundiagnosticProduct Catalogue 2024.pdf";

// import P12 from "assets/img_stock/FutureScientific/Products/New/GulfCoast.jpg";
// import P12GulfCoastPyloPlus from "assets/img_stock/FutureScientific/Products/PyloPlus-GulfCoast.pdf";
import g6pdpic from "assets/img_stock/FutureScientific/Products/New2/G6PD.jpeg";
import G6PD_Leaflet_2P from "assets/img_stock/FutureScientific/Products/G6PD_Leaflet_2P.pdf";


// 3-jan-2024
import Tspot from "assets/img_stock/FutureScientific/Products/New2/Tspot.jpeg";
import TspotPDF from "assets/img_stock/FutureScientific/Products/TSpot.pdf";














// import team1 from "assets/img_stock/FutureScientific/testimage.jpeg";

// import team2 from "assets/img_stock/FutureScientific/testimage.jpeg";
// import team3 from "assets/img_stock/FutureScientific/testimage.jpeg";
// import team4 from "assets/img_stock/FutureScientific/testimage.jpeg";
// import team5 from "assets/img_stock/FutureScientific/testimage.jpeg";
// import team6 from "assets/img_stock/FutureScientific/testimage.jpeg";


const useStyles = makeStyles(styles);

export default function   ProductSectionDetails() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title} style={{color:"black"}}>Our Products</h2>
      <div>
        <GridContainer>
          {/* minHeight:"400px", add before eid day 27-jun-2023 Dowload Brochure button opr niche hai es k lye same size ki image dalni hai */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"5%":"2%"}}>
            <div plain style={{width:width<500?"100%":"100%",}}>
              {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{}}> */}
                <img src={P1} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // marginRight:"-20%",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",
                }}/>
   
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              CHORUS
               </h4>
              <CardBody >
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Chorus is an automated immunoassay analyzer based on ready-to- use and single determination diagnostic devices for the diagnosis of infectious diseases, autoimmunity, endocrinology, tumor markers, allergy, bone metabolism & inflammation while ensuring maximum flexibility in organizing the clinical laboratory’s workflow.
                </p>
              </CardBody>
             <a   href={P1Brocher} download="ChorusBrochure.pdf">

              <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
                Dowload Brochure</Button>
                </a> */}
                <div style={{marginTop:width<500?"-115px":"-180px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                <a   href="https://www.diesse.it/en/products/chorus/" style={{color:"#252324",fontWeight:"bold",}}>
           
           <Button color="#196c90" size="sm" style={{backgroundColor:'#35a49b',fontSize:width<500?5:11,fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a   href={P1Brocher} download="ChorusBrochure.pdf"  style={{color:"#252324",fontWeight:"bold",}}>
           
<Button color="#196c90" size="sm" style={{backgroundColor:'#35a49b',fontSize:width<500?5:11,fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
            </div>
        
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={P2}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              CHORUS EVO
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                FS is committed to deliver unmatched sales, engineering and application support services with a vision is to position Future Scientific
              
                </p>
              </CardBody>
              <NavLink to="/Hematology" >
              <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>Dowload Brochure</Button>
              </NavLink>
              </div>

          </GridItem> */}
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"25%":"2%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P6} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              AUTO DOT

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Analytical system for diagnostic investigations with seroagglutination technique in microplate. AUTO-DAT allows the standardization of protocols with the automation of mixing, incubation and reading of results.  
                </p>
              </CardBody>
              
              <a   href={P6AutoDotBroucher} download="AutoDotBroucher.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                            <div style={{marginTop:width<500?"-140px":"-180px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>

                            <a   href="https://www.diesse.it/en/products/autodat/" style={{color:"#252324",fontWeight:"bold",           }}>
            
            <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
            See More</Button>
              </a> 
            <a  href={P6AutoDotBroucher} download="AutoDotBroucher.pdf"  style={{color:"#252324",fontWeight:"bold",           }}>
            
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
  
              </div>

          </GridItem> 
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":width<1300?"15%":"2%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P3}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
               CUBE 30 TOUCH
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Automatic continuous loading instrument for the determination of the ESR on 30 samples simultaneously directly from the EDTA CP tube. Automatic mixing with complete sample inversion, internal sample bar code reader & internal printer. 
                </p>
                
              </CardBody>
             
                         <a   href={P3Broucher} download="Cube30TouchBroucher.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                        <div style={{marginTop:width<500?"-140px":"-180px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        <a   href="https://www.diesse.it/en/products/cube-30-touch/" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a  href={P3Broucher} download="Cube30TouchBroucher.pdf"  style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
              </div>

          </GridItem>
        
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"15%"}}>
            {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
            <div plain style={{width:width<500?"100%":"100%",}}>
              
              {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}
                <img src={P4}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              MINI CUBE
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Automatic continuous loading instrument for the determination of the ESR on 4 samples simultaneously directly from the EDTA CP tube. USB and Bluetooth connector for personal computer, smart-phone and tablet. External bar code reader and printer. 
                </p>
              </CardBody>
            
                                        <a   href={P4MiniCubeBrochure} download="MiniCubeBrochure.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                        <div style={{marginTop:width<500?"-140px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        <a   href="https://www.diesse.it/en/products/mini-cube/" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a  href={P4MiniCubeBrochure} download="MiniCubeBrochure.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"15%"}}>
            {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
            <div plain style={{width:width<500?"100%":"100%",}}>
              
              {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}
                <img src={Tspot}  alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              T spot
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Automatic continuous loading instrument for the determination of the ESR on 4 samples simultaneously directly from the EDTA CP tube. USB and Bluetooth connector for personal computer, smart-phone and tablet. External bar code reader and printer. 
                </p>
              </CardBody>
            
                                        <a   href={P4MiniCubeBrochure} download="MiniCubeBrochure.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                        <div style={{marginTop:width<500?"-140px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        <a   href="https://tspot.asia/" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a  href={TspotPDF} download="Tspot.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
            </div>
          </GridItem>
         
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"15%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P5} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              BLUE DIVER

               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                BlueDiver Instrument is a compact and innovative machine designed to process nitrocellulose strips in ready-to-use cartridges containing the different reagents for autoimmunity profiling (ANA, ENA, Celiac, Phospholipids and ANCAs etc..). In addition, the BlueDiver Instrument's specific built and its standardized test procedures allow the testing of 24 either similar or different samples as well as the combination of different test strips in one same run. 
                </p>
              </CardBody>
              
              <a   href={P5BlueDiverBroucher} download="BlueDiverBroucher.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                      <div style={{marginTop:width<500?"-95px":"-140px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                      <a   href="https://www.d-tek.be/en/products/6-machines-and-software.html" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a  href={P5BlueDiverBroucher} download="BlueDiverBroucher.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
          
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
  
  </div>
              
              </div>

          </GridItem>
        
              {/* <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"20%":"15%"}}> */}
              <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"20%":"12%"}}>
          
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P7} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              HELIFAN

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                FANhp is designed for easy & accurate diagnosis of H. pylori from breath samples in bags.FANhp is ideal for hospitals , Laboratories &  doctor clinics who want to introduce 13C-urea breath testing for detecting Helicobacter pylori infections at reasonable cost with greater accuracy.     
                </p>
              </CardBody>
              
                        <a   href={P8hELIFANBreath} download="HeliFanBreath.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                      <div style={{marginTop:width<500?"-140px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                      <a   href="https://fan-gmbh.de/home/produkte/fanhp/" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
           See More</Button>
             </a> 
            <a  href={P8hELIFANBreath} download="HeliFanBreath.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
        
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
              </div>

          </GridItem>       <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"12%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P8} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              HELIFAN HYDROGE BREATH

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The LactoFAN2 is a powerful diagnostic tool for measuring exhaled Hydrogen concentrations in ppm, for the intolerance  of Lactose, Sucrose, Fructose, Investigation of SIBO (small intestine bacterial overgrowth) & determination of ITT (intestinal transit time).   
                </p>
              </CardBody>
              <a   href={P8hELIFANBreath} download="HeliFanBreath.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a>
             */}
                                 <div style={{marginTop:width<500?"-140px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                 <a   href="https://fan-gmbh.de/home/produkte/lactofan2/" style={{color:"#252324",fontWeight:"bold",           }}>
          
          <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
          See More</Button>
            </a> 
            <a  href={P8hELIFANBreath} download="HeliFanBreath.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
              
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"12%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P9} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              LIPOPRINT

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                The Lipoprint LDL subfraction test is an in vitro test device for new emerging risk factors & provides new ways of better cholestrol management. The Lipoprint Lipoprotein Subfractions Testing System analyzes LDL subfractions using high resolution polyacrilamide gel electrophoresis that separates and measures the amount of cholesterol in each LDL subfraction. 
               
                </p>
              </CardBody>
              
              <a   href={P9LipoPrintBroucher} download="LipoPrintBroucher.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                                    <div style={{marginTop:width<500?"-120px":"-175px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                    <a   href="https://quantimetrix.com/lipoprint/lipoprint-personalized-diagnostics/" style={{color:"#252324",fontWeight:"bold",           }}>
            
            <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
            See More</Button>
              </a> 
            <a  href={P9LipoPrintBroucher} download="LipoPrintBroucher.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
          
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
              </div>

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"25%":"12%"}}> */}
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"30%":"15%"}}>
         
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={g6pdpic} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              G6PD Analyzer

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                STANDARD G6PD is a quantitative glucose-6-phosphate dehydrogenase (G6PD) enzyme activity analyzer. G6PD & total Hb at once Provides ratio of G6PD to hemoglobin (U/g Hb).Rapid and simple test procedure Results in 2 minutes. Distinguish intermediate G6PD activity.

               
                </p>
              </CardBody>
             

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}   onClick={() => {
  alert("Not Available")
}}>
  Dowload Brochure</Button> */}
  {/* </a> */}
                   <div style={{marginTop:width<500?"-140px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>

  
  <a   href="https://www.sdbiosensor.com/product/product_view?product_no=183" style={{color:"#252324",fontWeight:"bold",           }}>
            
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
See More</Button>
  </a> 
  <a  href={G6PD_Leaflet_2P} download="G6PD_Leaflet_2P.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
           <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
             Dowload Brochure</Button>
             </a> 
           
  </div>
              </div>

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={P12} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
        GULF COAST

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                FS is committed to deliver unmatched sales, engineering and application support services with a vision is to position Future Scientific as a leader to introduce the new analytical technologies especially in Point of Care (POC) and innovative testing in Pakistan.
               
               
                </p>
              </CardBody>
              
              <a   href={P12GulfCoastPyloPlus} download="GulfCoastPyloPlus.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a>
              
      
              </div>

          </GridItem> */}
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"35%":"15%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P10} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"10px 10px 8px  #9d9d9d",

                }}/>
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              IMMUNDIAGNOSTIK

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                High quality German manufacturer  of Immunoassays (ELISA, EIA), LC-MS/MS ,HPLC applications, Molecularbiological tests (PCR) & Photometric tests.Immundiagnostik is more focused on Risk identification of diseases , Differential diagnostics & Therapy monitoring.
               
                </p>
              </CardBody>
            
                         <a   href={P10ProductCatalogForImmundiagnostic} download="ProductCatalog.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
                                <div style={{marginTop:width<500?"-120px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                <a   href="https://www.immundiagnostik.com/en/testkits" style={{color:"#252324",fontWeight:"bold",           }}>
            
            <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
            See More</Button>
              </a> 
            <a  href={Immundiagnostik} download="Immundiagnostik.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
  
  </div>
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:width<500?"25%":"15%"}}>
          {/* <div plain style={{width:width<500?"100%":"90%",borderWidth:"5px",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",}}> */}
          <div plain style={{width:width<500?"100%":"100%",}}>

          {/* <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}> */}

                <img src={P11} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // boxShadow:
                    // "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                    boxShadow:"5px  5px 4px  #9d9d9d",

                }}/>
                                         <div style={{marginTop:width<500?"-120px":"-190px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                         <a   href="https://www.demeditec.com/en/products" style={{color:"#252324",fontWeight:"bold",           }}>
            
            <Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
            See More</Button>
              </a> 
            <a  href={P10ProductCatalogForImmundiagnostic} download="ProductCatalog.pdf" style={{color:"#252324",fontWeight:"bold",           }}>
           
<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11}}>
  Dowload Brochure</Button>
  </a> 
 
  </div>
               
              {/* </GridItem> */}
              {/* <h4 className={classes.cardTitle}>
              DEMEDITEC

                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Demeditec Diagnostics is a supplier for diagnostic test kits for clinical laboratory and research labs as well. The technology of these test kits covers radioactive (RIAs) and non-radioactive (ELISA, rapid tests) origin. Demeditec Diagnostics is certified according to ISO 9001 and ISO 13485.
               
                </p>
              </CardBody>
          
                         <a   href={P10ProductCatalogForImmundiagnostic} download="ProductCatalog.pdf">

<Button color="#196c90" size="sm" style={{           backgroundColor:'#35a49b',fontSize:width<500?5:11marginBottom:"5%"}}>
  Dowload Brochure</Button>
  </a> */}
              </div>

          </GridItem>
         
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>


              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require('assets/img_stock/Molecular_Genetics.JPG')} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Pathology & Microbiology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Clinical pathology & Microbiology department is equipped with Cobas U411 for urine analysis, fully automated Sperm health analyzer by Biola Russia , Automated  blood culture system  by BD USA. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/histopathology_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Histopathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/moleculer_dep_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Biology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  Our Gene expert system also provides Drug resistance to Tuberculosis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/pathology_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Special Pathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our special pathology department we are performing Cytogenetic studies, Platelet aggregation studies, Interferon gamma release assay ( T SPOT TB Test) for the accurate diagnosis of Tuberculosis , Immunoflourescence studies for Anti-nuclear antibodies, Urea Breath test for accurate diagnosis of H.Pylori infections, Hydrogen Breath Tests for the intolerances of different sugars.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/animations/virus (12).png")} alt="..." 
                  alt="..."
                  width={"70%"}
                  className={classes.imgFluid+" "+"funfact-one__virus-1"}
                />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Covid 19 PCR Lab
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                MPL has established a dedicated Covid-19 PCR testing Laboratory. This  BSL-2 Covid lab is equipped with state of the art PCR systems by Hibergene diagnostics Ireland. The high quality CE marked and IVD approved kits are being used for testing which are also made by Hibergene Diagnostics Ireland. Currently we have a PCR testing capacity of 2000 per day.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/molecular_gen_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Genetics
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our molecular genetics department is lead by Dr.Hani Akbar, a well-known clinical geneticist. We provide counseling and diagnostic facilities of genetic disorders to Patients and clinicians. We are collaborating with different international laboratories for outsource testing of genetic markers.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/x-ray.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Imaging & Radiology Department
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our Imaging & Radiology department we are providing digital X-Ray & Ultrasound facilities to our customers with the help of our highly qualified panel of Radiologists and sonologists . We are equipped with latest X-Ray and ultrasound machines to meet the clinician’s needs. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
         
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer >
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>Our Prime Location</h2>
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53115.91986215992!2d73.026057615748!3d33.68966667301035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf43bd76f797%3A0xada34418cc053a99!2sMetropole%20Laboratories%20Private%20Limited!5e0!3m2!1sen!2s!4v1598854374278!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
             
          </GridItem>
          </GridContainer> */}
      </div>
    </div>
  );
}
