import React from 'react';
import {View, StyleSheet, Text  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 3,
        width:"380em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View>
      <InvoiceTitle item={invoice} />
   <View style={styles.tableContainer}>
   <InvoiceTableHeader />
   <InvoiceTableRow items={invoice.reports} key={invoice.name} />
</View>
<View style={{paddingLeft:10, paddingTop:5}}>
     <Text style={{
        fontFamily: 'Helvetica-Bold'
     }}>Note:</Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Reporting time is subject to the sample submission.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>Please bring this form to collect the reports.</Text></Text>
     <Text>o <Text style={{fontSize:7, fontFamily:"Helvetica"}}>In case of any query regarding report, please call at the given number within 48 hours.</Text></Text>
     </View>
</View>
 
  
  );
  
  export default InvoiceItemsTable