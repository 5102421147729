import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Roboto Regular',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    password: {
        width: '50%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,

        paddingTop:2
    },
    id: {
        width: '50%',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        color:'white',
        fontSize:8,
        paddingTop:2
    },

  });

  const LoginCredientials = ({date}) => (
    <View style={styles.container}>
        <Text style={styles.id}>Login ID</Text>
        <Text style={styles.password}>Password</Text>
  </View>
  );
  
  export default LoginCredientials