import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import useWindowDimensions from "../../useWindowDimensions";



// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';



import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';
import AboutSection from './AboutSection'


import test_info from 'assets/img/test_info.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function WhoWeAre(props) {
  const { width } = useWindowDimensions();
useEffect(()=>{
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
},[])
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/building.jpeg")} style={{marginTop:width<500?0:100}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title} style={{marginTop:-40}}>Who We Are</h1>
              {/* <h4>
               Committed to Diagnostic Excellence
              </h4> */}
              <br />
            
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-150:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"1em"}} >
        {/* <GridContainer justify="center">
              <COVID19_PCR />
              </GridContainer> */}
          {/* <br /> */}
          {/* yhan se about section wala text and image aa rhi hai */}
           <AboutSection />
            {/* <FreeHS_Msg /> */}
         
        </div>
      </div>
      <Footer />
    </div>
  );
}
