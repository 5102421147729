import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily:'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items}) => {
    var rows=[] 
    items.map( item => {
        return rows.push(<View style={ {
        
            flexDirection: 'row',
            borderTopColor: 'BLACK',
            borderTopWidth:1,
            borderBottomColor: 'BLACK',
            borderBottomWidth:1,
            alignItems: 'center',
            width:"100%",
            fontStyle: 'bold'
            }} key={item.tracking_id}>
                <Text style={{ 
                     
                     width: '10%',
                     textAlign: 'left',
                     borderLeftColor: 'black',

                     borderLeftWidth: 1,
                     paddingLeft: 8,
                     height:'100%',
                     
                     
                     }}>{item.tracking_id}</Text>
                   
                 <Text style={{ 
                     
            width: '15%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%',
            
            
            }}>{item.sender}</Text>
              <Text style={{ 
                     
                     width: '10%',
                     textAlign: 'left',
                     borderLeftColor: 'black',

                     borderLeftWidth: 1,
                     paddingLeft: 8,
                     height:'100%',
                     
                     
                     }}>{item.car_no}</Text>
                      <Text style={{ 
                     
                     width: '15%',
                     textAlign: 'left',
                     borderLeftColor: 'black',

                     borderLeftWidth: 1,
                     paddingLeft: 8,
                     height:'100%',
                     
                     
                     }}>{item.driver_no}</Text>
             <Text style={{ 
                     
            width: '15%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 8
            
            
            }}>{item.branch}</Text>
             <Text style={{ 
                     
            width: '15%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%'
            
            
            }}>{item.arrival_date}</Text>
             <Text style={{ 
                     
            width: '10%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            }}>{item.arrival_time}</Text>
             <Text style={{ 
                     
            width: '10%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderRightWidth:1,
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            
            }}>{item.total_sample}</Text>
               
            </View>)
            }
    )
    

    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow