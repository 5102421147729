import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import Slide from "@material-ui/core/Slide";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';


import MaterialTable from 'material-table';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import LibraryBooks from "@material-ui/icons/LibraryBooks";
import DialogActions from "@material-ui/core/DialogActions";
import TestSelection from './TestSelection';
import * as firebase from 'firebase';

import url from '../../link'

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

const bill_style = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  

export default class Form extends React.Component {
  constructor(props){
    super(props)
    this.state={
      classicModal:false,
      setClassicModal:false,
      lab_tests:[],
      total_bill:6500,
      columns: [
        { title: 'Test Name', field: 'Test_Name' },
        {
          title: 'Test Price (PKR)',
          field: 'Test_Price',
          type: 'numeric' 
        },
      ],
      rows: [{
        Test_Name:'Covid-19 RNA by PCR', Test_Price:6500, Test_Code:'COVIDPCR'
      }
      ],
      submitButton:true,
      name:"",
      email:"",
      relation_flag:false,
      age:0,
      relation_name:'',
      gender:"Male",
      address:"",
      relation:"Self",
      contact_1:"",
      contact_2:"",
      patient_id:0,
      alert:false,
      alertBody:''
        
}
  }
  formatDate(date, format, utc) {
    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
    function ii(i, len) {
        var s = i + "";
        len = len || 2;
        while (s.length < len) s = "0" + s;
        return s;
    }
  
    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);
  
    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);
  
    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);
  
    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);
  
    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);
  
    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);
  
    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);
  
    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);
  
    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));
  
    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));
  
    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);
  
    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);
  
    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);
  
    format = format.replace(/\\(.)/g, "$1");
  
    return format;
  }
  
  submit(){
    // alert:true for open dialogbox(modal) that are written in last of this 
    if(this.state.rows.length==0){
      this.setState({alertBody:"Kindly Select the Test Via Lab Test Selection"})
      this.setState({alert:true})
      return
    }
    if(this.state.email.trim()=='' || !this.state.email.includes('@')){
      this.setState({alertBody:"Invalid Email Address"})
      this.setState({alert:true})
      return
    }
    if(this.state.contact_1.trim()=='' || this.state.contact_1.trim().length<10){
      this.setState({alertBody:"Invalid Mobile Number"})
      this.setState({alert:true})
      return
    }
    if(this.state.address.trim()==''){
      this.setState({alertBody:"Invalid Address"})
      this.setState({alert:true})
      return
    }
    if(this.state.relation_flag){
      if(this.state.relation_name.trim()==''){
        this.setState({alertBody:"Invalid "+this.state.relation+" Name"})
        this.setState({alert:true})
        return
      }
    }
    if(this.state.age==0){
      this.setState({alertBody:"Invalid Age"})
      this.setState({alert:true})
      return
    }
    if(this.state.gender=='Select'){
      this.setState({alertBody:"Invalid Gender"})
      this.setState({alert:true})
      return
    }
    if (firebase.apps.length === 0) {
    
  
      fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLoginAuthorize',{
      method: 'POST',
      'Content-Type':"application/json",
      body:'vUID='+localStorage.getItem('patient_id')+'&vRole=Patient&vLoginType=ID&vRandom=21211'
    }).then((res)=>res.json()).then( async(res)=>{
      if (firebase.apps.length === 0) {
        firebase.initializeApp(res[0]);
    }
  })
}
this.setState({submitButton:false})
    firebase.database().ref("counter").once('value', async (data)=>{
     
      var records=data.toJSON()
      var booking_id=parseInt(records.record)+1
      var patient_id = localStorage.getItem('patient_id')
      console.log(booking_id)
      console.log(patient_id)
      var d = new Date();
      var today= new Date();
      var time=this.formatDate(d, "h:mmtt")
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!

      var yyyy = today.getFullYear();
      if (dd < 10) {
              dd = '0' + dd;
          }
      if (mm < 10) {
              mm = '0' + mm;
          }
      var today = mm + '/' + dd + '/' + yyyy;
      firebase.database().ref("patient/"+patient_id+"/"+booking_id).set({
        name:this.state.name,
                email:this.state.email,
                contact_1:this.state.contact_1,
                contact_2:this.state.contact_2,
                latitude:0,
                longitude:0,
                tracking:false,
                relation_name:this.state.relation_name,
                relation_flag:this.state.relation_flag,  
                status:"RHS",
                discount:0,
                cnic:'',
                payable:this.state.total_bill,                
              age:this.state.age,
              gender:this.state.gender,
                trash:false,
                image:false,
                relation:this.state.relation,
                location_flag:false,
                lab_tests:this.state.rows,
                total_price:this.state.total_bill,
                booking_id:booking_id,
                date:today,
                time:time,
                address:this.state.address
      }).then(async ()=>{
        var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", vSubject:"MPL Booking ID = "+booking_id,vTo:'najam@fsgroup.pk, support@mpl-labs.pk , nadeem@mpl-labs.pk , phlebotomy@mpl-labs.pk' ,Address:this.state.address, booking_id:booking_id+"", date:today, Email:this.state.email, Relation:this.state.relation, PhoneNo:this.state.contact_1+"", AlternativePhone:this.state.contact_2+"", Selected_Tests:this.state.rows.length+"",Total_Bill:this.state.total_bill+"" }
      console.log(SearchFormData)
        var vSearchStr = JSON.stringify(SearchFormData);
      
        var url ="https://reports.mpl-labs.pk:8443/4DACTION/WebHomeSamplingACKeMail?vQuery=" + vSearchStr+"&vUID=180820006&vRole=Admin"
      
        fetch(url).then((res)=>res.json()).then((response)=>{
          console.log(response)
        window.location.href="http://backup.mpl-labs.pk/Booking_History"
        })
      this.setState({alertBody:'Your Booking No ='+booking_id})
      this.setState({alert:true})  
      firebase.database().ref("counter").set({
        record:booking_id
  })
             
      })
        
      
     
    })
  


  }
  componentWillMount(){
    var name=localStorage.getItem('patient_name')
    var patient_id = localStorage.getItem('patient_id')
    var gender= localStorage.getItem('gender')
    var mobile= localStorage.getItem('mobile')
    var email= localStorage.getItem('email')
    var age= localStorage.getItem('age')
    this.setState({patient_id:patient_id})
    this.setState({name:name})
    this.setState({gender:gender})
    this.setState({age:age})
    this.setState({contact_1:mobile})
    this.setState({email:email})
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLoginAuthorize',{
      method: 'POST',
      'Content-Type':"application/json",
      body:'vUID='+localStorage.getItem('patient_id')+'&vRole=Patient&vLoginType=ID&vRandom=21211'
    }).then((res)=>res.json()).then( async(res)=>{
      if (firebase.apps.length === 0) {
        firebase.initializeApp(res[0]);
    }
    })
  }
  CloseModal=()=>{
    this.setState({classicModal:false})

  }

  addTest=(tests)=>{
    this.setState({rows:tests})
    var total_bill=0
    tests.map((item)=>{
      total_bill=total_bill+item.Test_Price
    })
    this.setState({total_bill:total_bill})
  }
  
  classes=()=>useStyles()
  BillStyles=()=>bill_style()
  render(){
  return (
    <div className={this.classes.section}>
       <GridContainer justify="center">
      
         <h5 style={{color:'black'}}>Service is <b style={{color:'red'}}>Only</b> available in <b style={{color:'red'}}>Islamabad</b> & <b style={{color:'red'}}>Rawalpindi</b></h5>
              </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={this.classes.title}>Home Sampling</h2>
          
          <form>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField id="standard-basic" label="Your Name" fullWidth={true}
              value={this.state.name}
             
              />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <TextField id="standard-basic" label="Your Email" fullWidth={true}
              value={this.state.email}
              type="email"
              onChange={(e)=>{
                this.setState({email:e.target.value})
              }}
              />
              
              </GridItem>
          
                <br />
                <GridItem xs={12} sm={12} md={12} lg={12}>
                <br />
                <MaterialTable
      title="COVID-19 Lab Test" 
      columns={this.state.columns}
      data={this.state.rows}
          />
          </GridItem>
         
          <GridItem xs={6} sm={6} md={6} lg={6}>
           
          <Paper style={
              {
                marginTop:"0.5em",
                padding:'1em',
                textAlign:'center'
              }
            }><b>Total Bill</b></Paper>
              </GridItem>
              
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <Paper style={
              {
                marginTop:"0.5em",
            
                padding:'1em',
                textAlign:'center'
              }
            }><b>{this.state.total_bill}</b></Paper>
              </GridItem>

             
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <br/>
              <FormControl variant="filled" className={this.classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Booking for :</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={this.state.relation}
          onChange={(e)=>{
            if(e.target.value=='Self'){
              this.setState({relation_flag:false})        
            }else{
              this.setState({relation_flag:true})
              this.setState({gender:'Select'})        
              this.setState({age:0})
            }
            this.setState({relation:e.target.value})
          }}
        >
          
          <MenuItem value={"Self"}>Self</MenuItem>
          <MenuItem value={"Spouse"}>Spouse</MenuItem>
          <MenuItem value={"Child"}>Child</MenuItem>
          <MenuItem value={"Sibling"}>Sibling</MenuItem>
          <MenuItem value={"Parent"}>Parent</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </FormControl>
                </GridItem>
                {this.state.relation_flag ?
               <GridItem xs={12} sm={12} md={12} lg={12}>
                 <br />
               <TextField id="standard-basic" label={this.state.relation+" Name"} fullWidth={true}
               value={this.state.relation_name}
               type="text"
               onChange={(e)=>{
                 this.setState({relation_name:e.target.value})
               }}
               />
               </GridItem>
               :
               <></>  
              }
                <GridItem xs={12} sm={12} md={6}>
                <br/>
               
              <TextField id="standard-basic" label="Your Age" fullWidth={true} 
              type="number"
              value={this.state.age}
              onChange={(e)=>{
                this.setState({age:e.target.value})
              }}
              />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <br/>
              <FormControl variant="filled" className={this.classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Gender :</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={this.state.gender}
          onChange={(e)=>{
            this.setState({gender:e.target.value})
          }}
        >
          <MenuItem disabled value={"Select"}>Select</MenuItem>
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
          </Select>
      </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <br/>
              <TextField id="standard-basic" label="Phone No." fullWidth={true}
              value={this.state.contact_1}
              type="number"
              onChange={(e)=>{
                this.setState({contact_1:e.target.value})
              }}
              />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <br/>

              <TextField id="standard-basic" label="Alternative Phone No. (Optional)" fullWidth={true}
              value={this.state.contact_2}
              type="number"
              onChange={(e)=>{
                this.setState({contact_2:e.target.value})
              }}
               />
              </GridItem>
             
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              <TextField
              style={{width:'100%'}}
                label="Address"
                multiline={5}
                value={this.state.address}
                onChange={(e)=>{
                  this.setState({address:e.target.value})
                 
                }}
              />
              <br />
              <br />
              <br />
             </GridItem>
                <Button 
                 block
                 style={{backgroundColor:'#196c90'}}
                 disabled={!this.state.submitButton}
                 onClick={()=>{
                  this.submit()
                 }}
                >Request for Free Home Sampling</Button>
             </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <Dialog
                  classes={{
                    root: this.classes.center,
                    paper: this.classes.modal
                  }}
                  open={this.state.alert}
                  TransitionComponent={Transition}
                  keepMounted
                  maxWidth={'sm'}
                  fullWidth={true}
                  onClose={() => this.setState({alert:false})}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={this.classes.modalHeader}
                  >
              <h3><b>Home Sampling</b></h3>
              
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={this.classes.modalBody}
                    
                  >
                    {this.state.alertBody}
                   </DialogContent>
                  <DialogActions className={this.classes.modalFooter}>
                   
                    <Button
                      onClick={() => this.setState({alert:false})}
                      color="danger"
                      simple
                    >
                      <b>Ok</b>
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>
         
    </div>
  );
                }
}
