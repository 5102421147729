import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// import Button from "components/CustomButtons/Button.js";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";
import "./PrincipleSectionDetail.css"



// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// import team1 from "assets/img_stock/bio_chem_1.JPG";
import Diesse from "assets/img_stock/FutureScientific/Principles/new/DIESSE.png";

import dtec from "assets/img_stock/FutureScientific/Principles/new/D-Tek logo.png";
import oxfordimmatec from "assets/img_stock/FutureScientific/Principles/new/Oxford.png";
import fisher from "assets/img_stock/FutureScientific/Principles/new/FAN Logo.png";
import sdbiosensor from "assets/img_stock/FutureScientific/Principles/new/SD.png";
import Quantimetrix from "assets/img_stock/FutureScientific/Principles/new/Quantemetric.jpeg";
import immunudiagnostic from "assets/img_stock/FutureScientific/Principles/new/Imun diagnostic.png";
import demeditec from "assets/img_stock/FutureScientific/Principles/new/demeditec.jpeg";
import GulfCoastDiagnostic from "assets/img_stock/FutureScientific/Principles/new/Gulf logo.jpeg";
import omegadx from "assets/img_stock/FutureScientific/Principles/new/Omega diagnostic.jpeg";
import dummy from "assets/img_stock/FutureScientific/testimage.jpeg";







const useStyles = makeStyles(styles);

export default function   ProductSectionDetails() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title} style={{color:"black"}}>Our Principles</h2>
      <div>
        <GridContainer>
          {/* minHeight:"400px", add before eid day 27-jun-2023 see more button opr niche hai es k lye same size ki image dalni hai */}
          <GridItem  xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
            <div onClick={()=>{
          window.open('https://www.diesse.it/en/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem  xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>
                <img src={Diesse} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    // marginRight:"-20%",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES ONE
               </h4> */}
              <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >
                <p  style={{textAlign:"justify",alignSelf:"center"}}>
                Monteriggioni, Italy
                </p>
               
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>
                www.diesse.it
                </p>
                
              </CardBody>
              {/* <NavLink to="/" style={{marginBottom:"5%"}} >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}

           {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                  <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
            </div>
          
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.d-tek.be/en/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={dtec}  alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
             D-TEC
               </h4> */}
                           <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                           <p  style={{textAlign:"justify",alignSelf:"center"}}>

                Mons, Belgium
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                www.d-tek.be
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.oxfordimmunotec.com/international/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={oxfordimmatec}  alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES THREE
               </h4> */}
                            <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                            <p  style={{textAlign:"justify",alignSelf:"center"}}>

                {/* Global Headquarters & UK Oxford Diagnostic Laboratories */}

Milton Park
Abingdon United Kingdom 

                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                  {/* {width<500?"www.oxfordimmunotec.com": "www.oxfordimmunotec.com" }
                   */}
                   www.oxfordimmunotec.com
               
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>
        {/* </GridContainer>
        <br/>
        <GridContainer> */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
            <div onClick={()=>{
          window.open('https://fan-gmbh.de/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>
                {/* <img src={fisher}  alt="..." style={{ */}
                <img src={fisher}  alt="..." style={{

                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES FOUR
               </h4> */}
                          <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                          <p  style={{textAlign:"justify",alignSelf:"center"}}>

                Leipzig, Germany

                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

www.fan-gmbh.de
                </p>

                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}

           {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                  <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.sdbiosensor.com')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={sdbiosensor} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES FIVE

               </h4> */}
                          <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                          <p  style={{textAlign:"justify",alignSelf:"center"}}>

                          Gyeonggi, South Korea
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                www.sdbiosensor.com
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://quantimetrix.com/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={Quantimetrix} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES SIX

                <br />
               </h4> */}
                          <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                          <p  style={{textAlign:"justify",alignSelf:"center"}}>

                Redondo Beach California, USA
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                www.quantimetrix.com
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>       <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.immundiagnostik.com/en/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={immunudiagnostic} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES SEVEN

                <br />
               </h4> */}
                         <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                         <p  style={{textAlign:"justify",alignSelf:"center"}}>

                   Bensheim,Germany
             
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                {width<500?"www.immundiagnostik.com": "www.immundiagnostik.com" }

                
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>       <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.demeditec.com/')
        }}  plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={demeditec} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES EIGHT

                <br />
               </h4> */}
                         <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                         <p  style={{textAlign:"justify",alignSelf:"center"}}>

                  Kiel, Germany 
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

www.demeditec.com
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.gulfcoastscientific.com/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
         
          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={GulfCoastDiagnostic} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              {/* <h4 className={classes.cardTitle}>
              PRINCIPLES NINE

                <br />
               </h4> */}
                        <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                        <p  style={{textAlign:"justify",alignSelf:"center"}}>

                  Oldsmar,Florida,USA 
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                {width<500?"www.gulfcoastscientific.com": "www.gulfcoastscientific.com" }

                
                </p>
              </CardBody>
              {/* <NavLink to="/" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#3d3d8a',marginBottom:"5%"}}>See More</Button>
              </NavLink> */}
              </div>

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"300px",marginTop:"5%"}}>
          <div onClick={()=>{
          window.open('https://www.omegadx.com/')
        }} plain className="card" style={{width:"90%",borderColor:"#3d3d8a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"1%"}}>

                <img src={omegadx} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              
                         <CardBody style={{display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column"}} >

                         <p  style={{textAlign:"justify",alignSelf:"center"}}>

                   Edinburgh, Scotland UK     
                </p>
                <p  style={{textAlign:"justify",alignSelf:"center",fontWeight:"bold"}}>

                www.omegadx.com
                </p>
              </CardBody>
              
              </div>

          </GridItem> */}
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>


              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require('assets/img_stock/Molecular_Genetics.JPG')} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Pathology & Microbiology
                <br />
               </h4>
              <CardBody>
                <p >
                Our Clinical pathology & Microbiology department is equipped with Cobas U411 for urine analysis, fully automated Sperm health analyzer by Biola Russia , Automated  blood culture system  by BD USA. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/histopathology_1.jpeg")} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Histopathology
                <br />
               </h4>
              <CardBody>
                <p >
                Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/moleculer_dep_1.jpeg")} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Biology
                <br />
               </h4>
              <CardBody>
                <p >
                Our Molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  Our Gene expert system also provides Drug resistance to Tuberculosis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/pathology_2.jpeg")} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Special Pathology
                <br />
               </h4>
              <CardBody>
                <p >
                In our special pathology department we are performing Cytogenetic studies, Platelet aggregation studies, Interferon gamma release assay ( T SPOT TB Test) for the accurate diagnosis of Tuberculosis , Immunoflourescence studies for Anti-nuclear antibodies, Urea Breath test for accurate diagnosis of H.Pylori infections, Hydrogen Breath Tests for the intolerances of different sugars.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/animations/virus (12).png")} alt="..." 
                  alt="..."
                  width={"70%"}
                  className={classes.imgFluid+" "+"funfact-one__virus-1"}
                />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Covid 19 PCR Lab
                <br />
               </h4>
              <CardBody>
                <p >
                MPL has established a dedicated Covid-19 PCR testing Laboratory. This  BSL-2 Covid lab is equipped with state of the art PCR systems by Hibergene diagnostics Ireland. The high quality CE marked and IVD approved kits are being used for testing which are also made by Hibergene Diagnostics Ireland. Currently we have a PCR testing capacity of 2000 per day.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/molecular_gen_2.jpeg")} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Genetics
                <br />
               </h4>
              <CardBody>
                <p >
                Our molecular genetics department is lead by Dr.Hani Akbar, a well-known clinical geneticist. We provide counseling and diagnostic facilities of genetic disorders to Patients and clinicians. We are collaborating with different international laboratories for outsource testing of genetic markers.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/x-ray.jpeg")} alt="..." style={{
                    maxWidth: "93%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Imaging & Radiology Department
                <br />
               </h4>
              <CardBody>
                <p >
                In our Imaging & Radiology department we are providing digital X-Ray & Ultrasound facilities to our customers with the help of our highly qualified panel of Radiologists and sonologists . We are equipped with latest X-Ray and ultrasound machines to meet the clinician’s needs. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
         
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer >
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>Our Prime Location</h2>
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53115.91986215992!2d73.026057615748!3d33.68966667301035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf43bd76f797%3A0xada34418cc053a99!2sMetropole%20Laboratories%20Private%20Limited!5e0!3m2!1sen!2s!4v1598854374278!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
             
          </GridItem>
          </GridContainer> */}
      </div>
    </div>
  );
}
