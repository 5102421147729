import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        marginTop: 80
    },
    reportTitle:{
        color: 'black',
        fontSize: 13,
        fontFamily:'Calibri Bold',
        textAlign: 'left',
        textTransform: 'uppercase',
        textDecoration:'underline'
    },
    GerrysTitle:{
        color: 'red',
        position:'absolute',
        fontSize: 13,
        marginTop:-15,
        fontFamily:'Calibri Bold',
        textAlign: 'left',
        textDecoration:'underline'
    }
  });


  const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
         {
title.toLowerCase().includes('turkish') || title.toLowerCase().includes('pegasus')
?
         <Text style={styles.GerrysTitle}>{"Referred By Gerry's"}</Text>
         :null }
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
  );
  
  export default InvoiceTitle