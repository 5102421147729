import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} >
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h2 className={classes.title} style={{marginTop:"0px"}}>Mission </h2>
          <h5 className={classes.description} style={{textAlign:"justify",color:"black"}}>
          We resolve to provide accurate, credible, cost-competitive, and prompt diagnostic services by
staying at the forefront of cutting-edge diagnostic technologies and continuously advancing
our professional expertise. We adhere to the best medical and ethical practices, compliance
with global standards of quality, and exercising continuous improvement processes to
empower our personnel to exceed expectations for a superior customer experience and to
build a long lasting relationship with our clients & patients.
          </h5>
          <h2 className={classes.title}>Vision</h2>
          <h5 className={classes.description} style={{textAlign:"justify",color:"black"}}>
          At Metropole, our vision is to be the premier and most credible diagnostic service provider in
Pakistan, recognized for offering reliable, accessible and affordable services, to contribute in
disease prevention through precise and prompt diagnosis, ultimately leading to build a healthier
nation.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%',marginTop:50}}>
          <img
                src={require("assets/img/mission.jpeg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer>
     
     
    </div>
  );
}
